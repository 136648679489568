import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Header, Button, Form, Dimmer, Loader } from 'semantic-ui-react';
import Layout from '../../../layouts/Layout';
import DefaultLayout from '../../../layouts/DefaultLayout';
import WestpacPayment from './../../../components/WestpacPayment/WestpacPayment';
import { withCustomer } from '../../../contexts/Customer';
import { withRouter } from '../../../utils/useRouter';
import Modal from './../../../components/Modal';
import { withTheme } from '../../../contexts/Theme'; 

export class PaymentSettingsAdd extends React.Component {
  state = {
    adding: false,
    loading: false,
    editing: false,
    errors: [],
    accounts: [],
    error: false,
  };

  registerPaymentDetails = (singleUseToken) => {
    const { customer } = this.props.customer;

    const url = process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/registerPaymentAccount';
    const body = {
      SingleUseToken: singleUseToken,
      CustomerID: customer.customerID,
    };

    return axios.post(url, body);
  };

  saveNewCard = () => {
    this.setState({ loading: true });

    this.westpacPayment.submit((err, token) => {
      if (!err) {
        let singleUseToken = token.singleUseToken.singleUseTokenId;

        this.registerPaymentDetails(singleUseToken)
          .then(() => {
            this.props.router.navigate('/accountSettings/payments');
          })
          .catch((err) => {
            this.setState({
              error: err.message || true,
            });
          })
          .finally(() => this.setState({ loading: false }));
      } else {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { theme } = this.props.theme; // Access theme from props

    return (
      <DefaultLayout 
      whiteBackground
      pagetitle="Card details"
    >
        <Header
          size="large"
          textAlign="left"
          style={{ color: "#000000" }} // forcing header to be balck to match with form
        >
          Add a Card
        </Header>

        <Modal
          name="payment-settings/add/error"
          size="tiny"
          open={!!this.state.error}
          onClose={() => this.setState({ error: false })}
        >
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{this.state.error}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ error: false })}>OK</Button>
          </Modal.Actions>
        </Modal>

        <Form as="div">
          <Dimmer inverted active={this.state.loading}>
            <Loader />
          </Dimmer>

          <WestpacPayment
            basic
            ref={(cmp) => (this.westpacPayment = cmp)}
            customer={this.props.customer}
          />
          <Form.Button fluid secondary onClick={() => this.saveNewCard()}>
            Save
          </Form.Button>
          <Form.Field>
            <Button as={Link} to="." fluid basic>
              Cancel
            </Button>
          </Form.Field>
        </Form>
      </DefaultLayout>
    );
  }
}

export default withRouter(withCustomer(withTheme(PaymentSettingsAdd)));
