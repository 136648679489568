import moment from 'moment-timezone';


export default class PaymentExtension {

  constructor(data) {

    this.accountId = data.AccountId;
    this.amountOutstanding = data.Amount;
    this.dateDue = data.DueDate;
    this.status = data.Status;
    this.billId = data.BillIdToPay;
    this.isExt = true;

    console.log('Creating PaymentExtension with data:', data);
  }

  get isOverdue() {
    return moment(this.dateDue).startOf('day').diff(moment().startOf('day'), "days") < 0;
  }

  // a paymentExtension will still exist even when it's paid off
  // so if the amount is $0, it's no longer active
  get isActive() {
    return this.amountOutstanding !== 0 && this.status !== "Canceled";
  }

}
