import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { withApp } from '../../../contexts/App';
import Layout from '../../../layouts/Layout';
import FullWidthLayout from "../../../layouts/FullWidthLayout";
import './ConcessionForm.scss';

const ConcessionForm = (props) => {
  const navigate = useNavigate();
  const { app } = props;
  const [frameHeight, setFrameHeight] = useState('1000px');
  const lastHeight = useRef(1000);
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        // Handle height changes
        if (event.data.type === 'frameHeight') {
          const newHeight = parseInt(event.data.height);
          if (Math.abs(newHeight - lastHeight.current) > 5) {
            lastHeight.current = newHeight;
            setFrameHeight(`${newHeight + 5}px`);
          }
        }
        
        // Handle confirmation page message
        if (event.data.type === 'confirmationPage') {
          window.scrollTo(0, 0); // Instant scroll to top
        }
      } catch (error) {
        console.warn('Error handling iframe message:', error);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (app.pageTitle !== "Concession application") {
      app.set({ pageTitle: "Concession application" });
    }
  }, [app]); 

  const formUrl = `${process.env.REACT_APP_AURORA_WEBSITE}/residential/payments/concession-card-application-form?display=stripped&origin=auroraplus`;

  return (
    <Layout layout={FullWidthLayout}>
      <div className="concession-form-container">
        <div className="concession-form-wrapper" style={{ height: frameHeight }}>
          <iframe
            ref={iframeRef}
            src={formUrl}
            title="Concession application"
            className="concession-form-frame"
            onError={(e) => console.warn('Iframe error:', e)}
            sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals"
          />
        </div>
      </div>
    </Layout>
  );
};

export default withApp(ConcessionForm); 