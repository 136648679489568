import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button, Header, Item, Modal, Icon, Loader} from 'semantic-ui-react'
import axios from 'axios';
import Responsive from "../../components/Responsive/Responsive";
import {withApp} from '../../contexts/App';
import CustomerContext, {withCustomer} from '../../contexts/Customer';
import { withTheme } from '../../contexts/Theme';
import Layout from '../../layouts/Layout';
import ExternalLink from '../../components/ExternalLink';
import Log from '../../utils/Log';
import Urls from '../../utils/Urls';
import { SolidCard } from '../../components/SolidCard/SolidCard';
import './AccountSettings.css';
import InfoIcon from '../../components/Icons/InfoIcon';
import Analytics from '../../utils/Analytics';

const PENSION_TYPE_MAP = {
  '27': 'Veterans Affairs Concession Card',
  '28': 'Pensioner Concession Card',
  '29': 'Health Care Card',
  '32': 'IMMI Card',
  '33': 'Tasmanian Concession Card',
};

const CONCESSION_STATUS_MAP = {
  'NEW': 'Under Review',
  'PEN': 'Under Review',
  'PENFAIL': 'Inactive',
  'VER': 'Active',
  'NOTVER': 'Inactive',
  'RVALREQ': 'Active',
  'RVALPEN': 'Under Review'
};

const CONCESSION_SUBMISSION_KEY = (premiseId) => `concessionSubmission_${premiseId}`;

/**
 * AccountSettings Component
 * Displays and manages user account information including:
 * - Account details
 * - Life support status
 * - Concession information
 * - Contact details
 */
export class AccountSettings extends React.Component {

  // Initial state for the component
  state = {
    accountNumber: '',
    accountHolder: '',
    email: '',
    postalAddress: '',
    mobilePhoneNumber: '',
    homePhoneNumber: '',
    processing: true,
    abn: '',
    modal: {
      isOpen: false,
      description: "",
      title: "",
    },
    isLoading: true,
    showCopyMessage: false
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showCopyMessage: false
    };
  }

  // Lifecycle Methods
  componentDidMount() {
    // Track page view
    Analytics.event({
      category: 'Account',
      action: 'View Account Details'
    });
    
    this.getContactDetails();
  }

  componentDidUpdate = (nextProps) => {
    const { customerPremise } = this.props.customer;
    if (customerPremise && customerPremise !== nextProps.customer.customerPremise) {
      this.getContactDetails();
    }
  }

  /**
   * Fetches contact details for the current customer premise
   * Includes account details, concession status, and contact information
   */
  getContactDetails = async () => {
    const { customer, customerPremise } = this.props.customer;

    if (!customerPremise || !customerPremise.parentAccountID) return;

    try {
      // Set initial account details
      this.setState({
        accountNumber: customerPremise.parentAccountNumber,
        accountNumberCheckDigit: customerPremise.parentAccountNumberCheckDigit,
        accountHolder: `${customer.firstName} ${customer.lastName}`,
        abn: customer.abn,
      });

      // Fetch concession and contact details in parallel
      const [concessionResponse, contactResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_BASE_URI}premises/HasConcession?accountID=${customerPremise.parentAccountID}&serviceAgreementID=${customerPremise.serviceAgreementID}`),
        axios.get(`${process.env.REACT_APP_API_BASE_URI}premises/contact?accountID=${customerPremise.parentAccountID}`)
      ]);

      this.setState({
        hasConcessions: concessionResponse.data === true,
        email: customer.emailAddress,
        postalAddress: contactResponse.data.PostalAddress.DisplayName,
        mobilePhoneNumber: contactResponse.data.PhoneNumbers.MobilePhoneNumber,
        homePhoneNumber: contactResponse.data.PhoneNumbers.HomePhoneNumber,
        isLoading: false
      });
    } catch (error) {
      Log.error('Error fetching contact details:', error);
      this.setState({ isLoading: false });
    }
  }

  // Modal Management Methods
  openModal = () => {
    this.setState({
      modal: {
        isOpen: true,
        title: "Inactive Concession",
        description: (
          <div>
            <p>
              Your concession is no longer active. If you believe you are still eligible for the Annual Electricity Concession Discount, please send through valid concession card details to:
              <br />
              <span className="email-container">
                <a 
                  href="#"
                  onClick={this.handleCopyEmail}
                  className="email-link"
                  style={{ 
                    color: this.props.theme.theme.primary.color
                  }}
                >
                  payments.concessions@auroraenergy.com.au
                </a>
              </span>
              This information will be re-validated with the relevant agency to ensure your concession is reinstated.
            </p>
          </div>
        )
      }
    });
  }

  closeModal = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  }

  openLifeSupportModal = () => {
    this.setState({
      modal: {
        isOpen: true,
        title: "Life Support",
        description: "If you are eligible for the Life Support concession, you can find further details on the Usage page."
      }
    });
  }

  // Add new method to check for pending submission
  checkPendingConcessionSubmission = () => {
    const { customerPremise } = this.props.customer;
    if (!customerPremise) return null;

    const submissionData = localStorage.getItem(CONCESSION_SUBMISSION_KEY(customerPremise.identifier));
    if (!submissionData) return null;

    const submission = JSON.parse(submissionData);
    
    // Clear submission if it's older than 2 weeks
    const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000;
    if (Date.now() - submission.timestamp > twoWeeksInMs) {
      localStorage.removeItem(CONCESSION_SUBMISSION_KEY(customerPremise.identifier));
      return null;
    }

    return submission;
  }

  handleCopyEmail = (e) => {
    e.preventDefault();
    const email = 'payments.concessions@auroraenergy.com.au';
    
    navigator.clipboard.writeText(email)
      .then(() => {
        const link = e.target;
        link.classList.add('copied');
        link.textContent = 'Email address copied to clipboard';
        setTimeout(() => {
          link.textContent = email;
          link.classList.remove('copied');
        }, 6000);
      })
      .catch(() => {
        // Silently fail if clipboard copy doesn't work
      });
  };

  render() {
    const { customerPremise, hasLoaded, customer, offers, premises } = this.props.customer;
    const { theme } = this.props.theme;
    const { pageConfig } = this.props.customer;

    // Show loading state while data is being fetched
    if (this.state.isLoading || !hasLoaded) {
      return (
        <Layout pageTitle="Account details">
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 100px)',
          }}>
            <Loader 
              active 
              size="large"
            >
            </Loader>
          </div>
        </Layout>
      );
    }

    // Destructure state values
    const {
      hasConcessions,
      accountNumber,
      accountHolder,
      accountNumberCheckDigit,
      email,
      postalAddress,
      mobilePhoneNumber,
      homePhoneNumber,
      abn
    } = this.state;

    // Format account number with check digit
    const accountNumberWithCheckDigit = `${accountNumber || ''}${accountNumberCheckDigit || ''}`;
    
    // Determine concessions message based on customer type and status
    const concessionsMessage = customer && ((customer.isFromCCB && hasConcessions) || (customer.isFromHubCX && customer.customerType == "residential" && (hasConcessions || customer.lifeSupport))) 
      ? "Concessions applied to this account." 
      : "No Concessions applied to this account.";

    return (
      <Layout 
      pageTitle="Account details">
        <Responsive
          greaterThan="mobile"
          as={Header}
          textAlign="center"
          size="large"
          style={{
          marginBottom: '1.2em'
        }}>
          Account details
        </Responsive>

        <Item.Group className="notifications" >
          {/* Account Details Section */}
          {this.state.isLoading ? (
            <Item className="notification">
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '2em'
              }}>
                <Loader active size="small">Loading account details...</Loader>
              </div>
            </Item>
          ) : (
            <Item className="notification">
              <Item.Content>
                <Item.Header style={theme.notification.header}>Account Details</Item.Header>
                <Item.Description style={theme.notification.description}>
                  <div className="account-number">
                    <h4>Account Number</h4>
                    <div>{accountNumberWithCheckDigit}</div>
                  </div>

                  <div className="primary-account">
                    <h4>Primary Account Holder</h4>
                    <div>{accountHolder}</div>
                  </div>

                  {abn && (
                    <div className="abn">
                      <h4>ABN</h4>
                      <div>{abn}</div>
                    </div>
                  )}

                  {customerPremise && (
                    <>
                      {customerPremise.lifeSupport === "Y" && (
                        <div className="life-support-section">
                          <div className="life-support">
                            <h4>Life Support</h4>
                            <InfoIcon
                              height="18"
                              style={{ 
                                marginLeft: '5px',
                                cursor: 'pointer',
                                color: theme.primary.color,
                                display: 'inline-flex'
                              }}
                              onClick={this.openLifeSupportModal}
                            />
                          </div>
                          <div className="life-support-text">
                            This account is registered for Life Support.
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Item.Description>
              </Item.Content>
            </Item>
          )}

          {/* Concession Details Section */}
          {customer.customerType === "residential" && (
            <Item className="notification concessions-section">
              <Item.Content>
                <Item.Header style={theme.notification.header}>
                  <div>
                    Your Concession Details
                  </div>
                </Item.Header>
                <Item.Description style={theme.notification.description}>
                  {(() => {
                    // Check for pending submission first
                    const pendingSubmission = this.checkPendingConcessionSubmission();
                    if (pendingSubmission && !customerPremise.concessionDetail) {
                      return (
                        <div className="no-concessions">
                          <h4>Concession Application In Progress</h4>
                          <p>Thanks for submitting your concession details. We're processing your request and will update your concession status soon.</p>
                        </div>
                      );
                    }

                    // Check if any other premise has concessions
                    const otherPremiseHasConcession = this.props.customer.premises?.some(premise => 
                      premise.identifier !== customerPremise.identifier && 
                      premise.concessionDetail !== null
                    );

                    // If this premise doesn't have concessions but another does
                    if (otherPremiseHasConcession && !customerPremise.concessionDetail) {
                      return (
                        <div className="no-concessions">
                          <h4>No Annual Concession Discount applied to this account.</h4>
                        </div>
                      );
                    }

                    // Original concession detail logic for premises with concessions
                    return (
                      <>
                        {this.props.customer.customerPremise.concessionDetail && 
                          !(this.props.customer.customerPremise.concessionDetail.pensionType === '27' && 
                            this.props.customer.customerPremise.concessionDetail.concessionStatus === 'NOTVER') ? (
                          <>
                            <div className="concession-number">
                              <h4>Concession Number</h4>
                              <div>{this.props.customer.customerPremise.concessionDetail.pensionNumber}</div>
                            </div>

                            <div className="concession-row">
                              <div className="concession-type">
                                <h4>Concession Type</h4>
                                <div className="concession-text">
                                  {PENSION_TYPE_MAP[this.props.customer.customerPremise.concessionDetail.pensionType]}
                                </div>
                                {this.props.customer.customerPremise.concessionDetail.medicalCoolingConcession === 'Y' && (
                                  <div>Medical Cooling Concession</div>
                                )}
                              </div>

                              <div className="concession-status">
                                <h4>Status</h4>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {(() => {
                                    const concessionStatus = this.props.customer?.customerPremise?.concessionDetail?.concessionStatus;
                                    const mappedStatus = CONCESSION_STATUS_MAP[concessionStatus];
                                    const isInactive = mappedStatus === 'Inactive' || !concessionStatus;
                                    
                                    return (
                                      <>
                                        {mappedStatus || 'Inactive'}
                                        {isInactive && (
                                          <InfoIcon 
                                            height="18"
                                            style={{ 
                                              marginLeft: '5px',
                                              cursor: 'pointer',
                                              color: theme.primary.color,
                                              display: 'inline-flex'
                                            }}
                                            onClick={this.openModal}
                                          />
                                        )}
                                      </>
                                    );
                                  })()}
                                </div>
                                {this.props.customer.customerPremise.concessionDetail.medicalCoolingConcession === 'Y' && (
                                  <div>Active</div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="no-concessions">
                            <h4>No Annual Concession Discount applied to this account</h4>
                            <p>Do you have a concession card? You may be eligible for a discount on your electricity bill.</p>
                            {offers && offers.hasBillable && (
                              <Button 			
                                fluid
                                secondary 
                                as={Link} 
                                to="/concession/register"
                                style={{
                                  margin: "0 0 10px 0",

                                }}
                              >
                                Add Concession Card
                              </Button>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })()}
                </Item.Description>
              </Item.Content>
            </Item>
          )}

          {/* Contact Details Section */}
          {this.state.isLoading ? (
            <Item className="notification contact-section">
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '2em'
              }}>
                <Loader active size="small">Loading contact details...</Loader>
              </div>
            </Item>
          ) : (
            <Item className="notification contact-section">
              <Item.Content>
                <Item.Header style={theme.notification.header}>Your Contact Details</Item.Header>
                <Item.Description style={theme.notification.description}>
                  <div className="contact-details">
                    <h4>Email Address</h4>
                    <div className="contact-item">
                      {email}
                    </div>

                    <h4>Postal Address</h4>
                    <div className="contact-item">
                      <span>{postalAddress}</span>
                      <NavLink
                        className="edit-link"
                        to="/accountSettings/accountDetails/editAddress"
                        style={{ 
                          color: theme.links.color,
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          display: 'block',
                          textDecoration: theme.links.textDecoration
                        }}>
                        Edit
                      </NavLink>
                    </div>

                    <h4>Mobile Number</h4>
                    <div className="contact-item">
                      <span>{mobilePhoneNumber}</span>
                      <NavLink
                        className="edit-link"
                        to="/accountSettings/accountDetails/editPhoneNumbers"
                        style={{ 
                          color: theme.links.color,
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          display: 'block',
                          textDecoration: theme.links.textDecoration
                        }}>
                        Edit
                      </NavLink>
                    </div>

                    <h4>{pageConfig && pageConfig.productName === 'business' ? 'Work Phone Number' : 'Home Phone Number'}</h4>
                    <div className="contact-item">
                      <span>{homePhoneNumber}</span>
                      <NavLink
                        className="edit-link"
                        to="/accountSettings/accountDetails/editPhoneNumbers"
                        style={{ 
                          color: theme.links.color,
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                          display: 'block',
                          textDecoration: theme.links.textDecoration
                        }}>
                        Edit
                      </NavLink>
                    </div>
                  </div>
                </Item.Description>
              </Item.Content>
            </Item>
          )}
        </Item.Group>

		<Button
			size="small"
			style={{
				display: 'inline-block',
				marginTop: "1em",
				marginBottom: "1em"
			}}
			fluid
			secondary
			as={Link}
			to="/accountSettings/requestDeletion"
		>
			Delete my aurora+ account
		</Button>

        <Modal
          size='tiny'
          open={this.state.modal.isOpen}
          onClose={this.closeModal}
          style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
        >
          <Modal.Header>{this.state.modal.title}</Modal.Header>
          <Modal.Content>
            <Modal.Description>{this.state.modal.description}</Modal.Description>
          </Modal.Content>
          <Modal.Actions style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button
              secondary
              fluid
              onClick={this.closeModal}
            >
              OK
            </Button>
            {this.state.modal.title === "Life Support" && (
              <ExternalLink
                href="https://www.auroraenergy.com.au/help/aurora/usage/viewing-your-life-support-concession"
                style={{
                  backgroundColor: 'transparent',
                  border: `1px solid ${theme.primary.color}`,
                  color: theme.primary.color,
                  width: '100%',
                  padding: '11px 20px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  textDecoration: 'none',
                  margin: '0 0 20px 0',
                }}
              >
                Learn more
              </ExternalLink>
            )}
          </Modal.Actions>
        </Modal>

      </Layout>
      
    )
  }
}

export default withApp(withCustomer(withTheme(AccountSettings)));