import React from 'react';
import { Button, Header, Item, Modal, Icon } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { withApp } from '../../../contexts/App';
import { withCustomer } from '../../../contexts/Customer';
import { withTheme } from '../../../contexts/Theme';
import Layout from '../../../layouts/Layout';
import './ConcessionDetails.scss';
import { termsAndConditions } from './termsAndConditions';
import axios from 'axios';
import Log from '../../../utils/Log';
import ExternalLink from '../../../components/ExternalLink';
import Analytics from '../../../utils/Analytics';

const withRouter = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return <Component {...props} navigate={navigate} location={location} />;
  };
};

export class ConcessionDetails extends React.Component {
  constructor(props) {
    super(props);
    
    const defaultState = {
      currentStep: 1,
      modal: {
        isOpen: false,
        description: "",
        title: "",
      },
      formData: {
        nameMatches: false,
        concessionType: '',
        concessionNumber: ''
      },
      errors: {
        concessionNumber: ''
      },
      isSubmitting: false
    };

    // Get saved state from sessionStorage or use default values
    const savedState = JSON.parse(sessionStorage.getItem('concessionDetails')) || defaultState;

    this.state = savedState;
    
    // Block navigation when component mounts
    window.history.pushState(null, '', window.location.pathname);
  }

  componentDidMount() {
    this.updatePageTitle();
    window.addEventListener('popstate', this.handleBrowserBack);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep !== this.state.currentStep) {
      this.updatePageTitle();
    }
    if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      sessionStorage.setItem('concessionDetails', JSON.stringify(this.state));
    }
  }

  // Clear storage when reaching final step or leaving
  componentWillUnmount() {
    sessionStorage.removeItem('concessionDetails');
    window.removeEventListener('popstate', this.handleBrowserBack);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  }

  handleBrowserBack = (e) => {
    e.preventDefault();
    const { currentStep } = this.state;
    
    // If on the final step, redirect to account settings
    if (currentStep === 5) {
      this.props.navigate('/accountSettings');
      return;
    }
    
    // Otherwise handle normal back navigation
    if (this.state.currentStep > 1) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep - 1
      }));
      window.history.pushState(null, '', window.location.pathname);
    } else {
      const confirmLeave = window.confirm('Are you sure you want to leave? Your progress will be lost.');
      if (confirmLeave) {
        sessionStorage.removeItem('concessionDetails');
        this.props.navigate('/accountSettings');
      }
    }
  }

  handleBack = (e) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    
    const { currentStep } = this.state;
    
    // If on the final step, redirect to account settings
    if (currentStep === 5) {
      this.props.navigate('/accountSettings');
      return;
    }
    
    // Otherwise handle normal back navigation
    if (this.state.currentStep > 1) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep - 1
      }));
      window.history.pushState(null, '', window.location.pathname);
    } else {
      const confirmLeave = window.confirm('Are you sure you want to leave? Your progress will be lost.');
      if (confirmLeave) {
        sessionStorage.removeItem('concessionDetails');
        this.props.navigate('/accountSettings');
      }
    }
  }

  handleNext = async () => {
    const { currentStep, formData } = this.state;
    const { allPremises } = this.props.customer;
    
    // If we're on step 2 (concession type selection) and it's a pensioner or healthcare card
    if (currentStep === 2 && 
        (formData.concessionType === 'pensioner' || formData.concessionType === 'healthcare')) {
      
      // Filter for active premises
      const activePremises = allPremises?.filter(premise => 
        premise.serviceAgreementStatus === 'Active'
      );

      // If multiple active premises, redirect to concessionForm
      if (activePremises?.length > 1) {
        this.props.navigate('/concessionForm');
        return;
      }
    }
    
    // For DVA cards, redirect to the iframe form instead of external page
    if (currentStep === 2 && this.isExternalConcessionType(formData.concessionType)) {
      this.props.navigate('/concessionForm');
      return;
    }
    
    // If we're on step 4 (terms) and moving to step 5, submit the form
    if (currentStep === 4) {
      Analytics.event({
        category: "Account",
        action: "Submit Concessions Request"
      });
      await this.submitConcession();
      return;
    }
    
    this.setState(prevState => ({
      currentStep: prevState.currentStep + 1
    }));
  }

  submitConcession = async () => {
    const { formData } = this.state;
    const { customer, customerPremise } = this.props.customer;

    this.setState({ isSubmitting: true });

    try {
      // Map concession types to pension types
      const PENSION_TYPE_MAP = {
        'pensioner': '28', // Pensioner Concession Card
        'healthcare': '29', // Health Care Card
        'dva': '27'        // DVA Card
      };

      const getFirstName = (fullName) => {
        // Handle null or undefined
        if (!fullName) return '';
        
        // Trim spaces and handle multiple consecutive spaces
        const cleanName = fullName.trim().replace(/\s+/g, ' ');
        
        // Return first word (including any hyphens)
        return cleanName.split(' ')[0];
      };

      const payload = {
        AccountID: customerPremise.parentAccountID,
        CardFirstName: getFirstName(customer.firstName),
        CardSurname: customer.lastName,
        ConcessionNumber: formData.concessionNumber,
        ConcessionType: PENSION_TYPE_MAP[formData.concessionType]
      };

      const endpoint = process.env.REACT_APP_API_BASE_URI + 'customers/concessions/create';
      const response = await axios.post(endpoint, payload);

      // Check if response is successful (status 200)
      if (response.status === 200) {
        // Store submission status in localStorage
        const submissionStatus = {
          timestamp: Date.now(),
          premiseId: customerPremise.identifier,
          status: 'pending',
          submissionDate: new Date().toISOString()
        };
        localStorage.setItem(`concessionSubmission_${customerPremise.identifier}`, JSON.stringify(submissionStatus));

        // Move to success step
        this.setState({
          isSubmitting: false,
          currentStep: 5
        });
      } else {
        throw new Error('Non-200 response');
      }

    } catch (error) {
      Log.error('Error submitting concession:', error);
      
      this.setState({
        isSubmitting: false,
        modal: {
          isOpen: true,
          title: "Error",
          description: "Your concession information cannot be lodged at this time."
        }
      });
    }
  }

  openModal = () => {
    this.setState({
      modal: {
        isOpen: true,
        title: "Update your details",
        description: (
          <div style={{ textAlign: 'center' }}>
            You will need to update your details with Service Australia or contact us to update your Aurora Energy account details before you can add a concession card. 
            <br /><br />
            <ExternalLink 
              href="https://www.auroraenergy.com.au/faqs/payments/how-do-i-change-my-name-or-update-my-contact-details-my-aurora-account"
              style={{ 
                color: this.props.theme.theme.primary.color,
                textDecoration: 'underline'
              }}
            >
              Click here to find out more information.
            </ExternalLink>
          </div>
        )
      }
    });
  }

  closeModal = () => {
    this.setState({
      modal: {
        isOpen: false
      }
    });
  }

  handleConcessionTypeChange = (type) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        concessionType: prevState.formData.concessionType === type ? '' : type
      }
    }));
  }

  updatePageTitle = () => {
    const { currentStep } = this.state;
    let pageTitle;
    
    switch (currentStep) {
      case 1:
        pageTitle = "Register concession";
        break;
      case 2:
        pageTitle = "Select concession";
        break;
      case 3:
        pageTitle = "Register concession";
        break;
      case 4:
        pageTitle = "Terms and Conditions";
        break;
      case 5:
        pageTitle = "Registration complete";
        break;
      default:
        pageTitle = "Register concession";
    }

    this.props.app.set({ pageTitle });
  }

  renderStep1 = () => {
    const { theme } = this.props.theme;
    const { customer } = this.props.customer;
    const customerName = customer?.firstName ? `${customer.firstName} ${customer.lastName}` : '';
    
    return (
      <div className="step-content">
        <Header as="h1" className="step-header">Register your Concession Card</Header>
        <p>{customerName && ` ${customerName} `}</p>
        <p>
          If you have a concession card, you may be eligible for a discount on your electricity bill. The name on your concession card will need to match your Aurora Energy account name (above) in order for us to apply a discount. 
          <br /><br />Is the name on your concession card the same as the name on your Aurora 
          Energy account?
        </p>

        <div className="select-buttons">
          <div className="button-row">
            <Button 
              className="select-button"
              onClick={this.openModal}
              style={{
                backgroundColor: theme.selectButtons.default.backgroundColor,
                borderColor: theme.selectButtons.default.borderColor,
                color: theme.selectButtons.default.color
              }}
            >
              No
            </Button>
            <Button 
              className="select-button advance"
              onClick={this.handleNext}
              style={{
                backgroundColor: theme.selectButtons.continue.backgroundColor,
                borderColor: theme.selectButtons.continue.borderColor,
                color: theme.selectButtons.continue.color
              }}
            >
              Yes
            </Button>
          </div>
          <Button 
            className="select-button cancel"
            onClick={() => this.props.navigate('/accountSettings')}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  renderStep2 = () => {
    const { theme } = this.props.theme;
    const { formData } = this.state;
    
    const concessionTypes = [
      { key: 'pensioner', text: 'Pensioner Concession Card' },
      { key: 'healthcare', text: 'Health Care Card' },
      { key: 'dva', text: 'Veterans Affairs Concession Card' },
    ];

    // Set CSS variables for theming
    const radioGroupStyle = {
      '--border-color': theme?.selectBoxes?.borderColor,
      '--selected-border-color': theme?.selectBoxes?.selectedBorderColor,
      '--selected-background-color': theme?.selectBoxes?.selectedBackgroundColor,
      '--selected-text-color': theme?.selectBoxes?.selectedTextColor
    };

    return (
      <div className="step-content">
        <Header as="h1" className="step-header">Select concession type</Header>
        
        <div className="radio-group" style={radioGroupStyle}>
          {concessionTypes.map(type => (
            <div key={type.key} className="radio-item">
              <input
                type="radio"
                id={type.key}
                name="concessionType"
                value={type.key}
                checked={formData.concessionType === type.key}
                onChange={() => this.handleConcessionTypeChange(type.key)}
              />
              <label htmlFor={type.key}>
                {type.text}
              </label>
            </div>
          ))}
        </div>

        <div className="select-buttons">
          <div className="button-row">
            <Button 
              className="select-button"
              onClick={this.handleBack}
              style={{
                backgroundColor: theme.selectButtons.default.backgroundColor,
                borderColor: theme.selectButtons.default.borderColor,
                color: theme.selectButtons.default.color
              }}
            >
              Back
            </Button>
            <Button 
              className="select-button advance"
              onClick={this.handleNext}
              disabled={!formData.concessionType}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderStep3 = () => {
    const { theme } = this.props.theme;
    const { formData = {}, errors = {} } = this.state;
    
    const concessionType = [
      { key: 'pensioner', text: 'Pensioner Concession Card' },
      { key: 'healthcare', text: 'Health Care Card' },
      { key: 'dva', text: 'DVA Card' },
    ].find(type => type.key === formData.concessionType)?.text;

    return (
      <div className="step-content">
        <Header as="h1" className="step-header">Register your {concessionType}</Header>
        
        <p>
          If you have a concession card, you may be eligible for a discount on your electricity bill.  
          The name on your concession card will need to match the 
          name on your Aurora Energy account in order for us to apply a discount.
        </p>

        <div className="input-field">
          <label>Concession number</label>
          <input
            type="text"
            placeholder="Enter concession number"
            value={formData.concessionNumber || ''}
            onChange={this.handleConcessionNumberChange}
            maxLength={10}
            className={errors.concessionNumber ? 'error' : ''}
            style={{
              backgroundColor: theme.inputField.backgroundColor,
              color: theme.inputField.color,
              borderColor: errors.concessionNumber ? 'red' : theme.inputField.borderColor,
            }}
          />
          {errors.concessionNumber && (
            <div className="error-message">
              {errors.concessionNumber}
            </div>
          )}
        </div>

        <div className="select-buttons">
          <div className="button-row">
            <Button 
              className="select-button"
              onClick={this.handleBack}
              style={{
                backgroundColor: theme.selectButtons.default.backgroundColor,
                borderColor: theme.selectButtons.default.borderColor,
                color: theme.selectButtons.default.color
              }}
            >
              Back
            </Button>
            <Button 
              className="select-button advance"
              onClick={this.handleNext}
              disabled={!formData.concessionNumber || errors.concessionNumber}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderStep4 = () => {
    const { theme } = this.props.theme || {};
    const { formData } = this.state;
    
    const termsContainerStyle = {
      backgroundColor: theme?.termsContainer?.backgroundColor,
      color: theme?.termsContainer?.color,
      borderColor: theme?.termsContainer?.borderColor
    };

    const checkboxStyle = {
      color: theme?.termsContainer?.color // This will match the terms text color
    };

    const paragraphs = termsAndConditions.text.split('\n\n');
    return (
      <div className="step-content">
        <Header as="h1" className="step-header">Terms and Conditions</Header>
        
        <div className="terms-container" style={termsContainerStyle}>
          <div className="terms-content">
            {paragraphs.map((paragraph, index) => {
              if (index === paragraphs.length - 1) {
                // Replace the last paragraph's privacy statement text with the link
                const parts = paragraph.split('privacy collection statement');
                return (
                  <p key={index}>
                    {parts[0]}
                    {termsAndConditions.privacyLink}
                    {parts[1]}
                  </p>
                );
              }
              return <p key={index}>{paragraph}</p>;
            })}
          </div>
        </div>

        <div className="checkbox-container">
          <input
            type="checkbox"
            id="terms-checkbox"
            checked={formData.termsAccepted || false}
            onChange={(e) => this.setState(prevState => ({
              formData: {
                ...prevState.formData,
                termsAccepted: e.target.checked
              }
            }))}
          />
          <label htmlFor="terms-checkbox" style={checkboxStyle}>
            I agree to the Terms and Conditions and Privacy Collection statement
          </label>
        </div>

        <div className="select-buttons">
          <div className="button-row">
            <Button 
              className="select-button"
              onClick={this.handleBack}
              disabled={this.state.isSubmitting}
              style={{
                backgroundColor: theme.selectButtons.default.backgroundColor,
                borderColor: theme.selectButtons.default.borderColor,
                color: theme.selectButtons.default.color
              }}
            >
              Back
            </Button>
            <Button 
              className="select-button advance"
              onClick={this.handleNext}
              disabled={!formData.termsAccepted || this.state.isSubmitting}
              loading={this.state.isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderStep5 = () => {
    const { theme } = this.props.theme;
    const { formData } = this.state;
    
    // Map for concession types
    const concessionTypeMap = {
      'pensioner': 'Pensioner Concession Card',
      'healthcare': 'Health Care Card',
      'dva': 'DVA Card'
    };

    return (
      <div className="step-content">
        <Header as="h1" className="step-header">Thank you</Header>
        
        <p>
          Thank you for your {concessionTypeMap[formData.concessionType]} submission. We'll process your request within 
          3 working days and your concession will be applied from the date of processing. If there 
          are any issues, we'll let you know.
        </p>

        <div className="success-tick" style={{ color: theme.successIcon.color }}>
          <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80 0C35.6971 0 0 35.6971 0 80C0 124.303 35.6971 160 80 160C124.303 160 160 124.303 160 80C160 71.3942 158.846 62.8125 155.769 54.8077L145.769 64.6154C146.995 69.5433 147.692 74.4712 147.692 80C147.692 117.548 117.548 147.692 80 147.692C42.4519 147.692 12.3077 117.548 12.3077 80C12.3077 42.4519 42.4519 12.3077 80 12.3077C98.4615 12.3077 115.048 19.6635 126.731 31.3462L135.385 22.6923C121.226 8.53366 101.538 0 80 0ZM149.423 26.3462L80 95.7692L53.6538 69.4231L44.8077 78.2692L75.5769 109.038L80 113.269L84.4231 109.038L158.269 35.1923L149.423 26.3462Z" fill="currentColor"/>
          </svg>
        </div>

        <div className="submission-summary">
          <h3>Submission Summary</h3>
          <div className="summary-details">
            <p><strong>Concession Type:</strong> {concessionTypeMap[formData.concessionType]}</p>
            <p><strong>Concession Number:</strong> {formData.concessionNumber}</p>
          </div>
        </div>

        <div className="select-buttons">
          <Button 
            className="select-button advance"
            as={Link}
            to="/accountSettings"
          >
            Return to account details
          </Button>
        </div>
      </div>
    );
  }

  isExternalConcessionType = (type) => {
    const externalTypes = ['dva'];
    return externalTypes.includes(type);
  }

  renderExternalStep = () => {
    const { theme } = this.props.theme;
    const { formData } = this.state;
    
    const concessionTypeMap = {
      'dva': {
        name: 'DVA Card',
        pensionType: '27'
      }
    };

    const selectedConcession = concessionTypeMap[formData.concessionType];

    return (
      <div className="step-content">
        <Header as="h1">External Application Required</Header>
        
        <p>
          To register your {selectedConcession.name}, 
          you'll need to complete a web form on the Aurora Energy website. You will be redirected away from 
          aurora+ to complete the process.
        </p>

        <div className="select-buttons">
          <div className="button-row">
            <Button 
              className="select-button"
              onClick={this.handleBack}
              style={{
                backgroundColor: theme.selectButtons.default.backgroundColor,
                borderColor: theme.selectButtons.default.borderColor,
                color: theme.selectButtons.default.color
              }}
            >
              Back
            </Button>
            <Button 
              className="select-button advance"
              onClick={this.handleExternalRedirect}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  handleExternalRedirect = () => {
    this.props.navigate('/concessionForm');
  }

  validateConcessionNumber = (number) => {
    const concessionRegex = /^[0-9]{9}[A-Za-z]$/;
    return concessionRegex.test(number);
  }

  handleConcessionNumberChange = (e) => {
    const value = e.target.value.toUpperCase(); // Convert to uppercase
    
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        concessionNumber: value
      },
      errors: {
        ...prevState.errors,
        concessionNumber: value ? 
          (this.validateConcessionNumber(value) ? 
            '' : 
            'Concession number must be 9 digits followed by a letter (e.g. 123456789A)'
          ) : 
          'Concession number is required'
      }
    }));
  }

  render() {
    const { currentStep, modal } = this.state;
    const { theme } = this.props.theme;

    // Set theme variables at the root level
    const themeStyle = {
      '--border-color': theme?.selectBoxes?.borderColor,
      '--selected-border-color': theme?.selectBoxes?.selectedBorderColor,
      '--selected-background-color': theme?.selectBoxes?.selectedBackgroundColor,
      '--selected-text-color': theme?.selectBoxes?.selectedTextColor
    };

    // Helper function to safely render modal description
    const renderModalDescription = (description) => {
      if (React.isValidElement(description)) {
        return description;
      }
      if (typeof description === 'string') {
        return <p>{description}</p>;
      }
      return null;
    };

    return (
      <Layout>
        <div style={themeStyle}>
          {currentStep === 1 && this.renderStep1()}
          {currentStep === 2 && this.renderStep2()}
          {currentStep === 'external' && this.renderExternalStep()}
          {currentStep === 3 && this.renderStep3()}
          {currentStep === 4 && this.renderStep4()}
          {currentStep === 5 && this.renderStep5()}

          <Modal
            size='tiny'
            open={modal.isOpen}
            onClose={this.closeModal}
            style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
          >
            <Modal.Header>{modal.title}</Modal.Header>
            <Modal.Content>
              <div style={{ textAlign: 'center' }}>
                {renderModalDescription(modal.description)}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                fluid
                onClick={this.closeModal}
              >
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Layout>
    );
  }
}

export default withRouter(withApp(withCustomer(withTheme(ConcessionDetails))));

