import React from 'react';
import axios from 'axios';

import {Header, Button, Confirm, Dimmer, Loader} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react';
import ValidatedInput from '../../../components/Forms/ValidatedInput';
import ValidatedDropdown from '../../../components/Forms/ValidatedDropdown';
import ValidatedCheckbox from '../../../components/Forms/ValidatedCheckbox';
import { withRouter } from '../../../utils/useRouter';
import Layout from '../../../layouts/Layout';
import {withCustomer} from '../../../contexts/Customer'
import Modal from './../../../components/Modal';
import Log from '../../../utils/Log';

export class PaymentSettingsEdit extends React.Component {

  constructor() {
    super();

    let now = new Date();
    this.currentMonth = now.getMonth();
    this.currentYear = now.getFullYear();

    this.generateexpiryDateMonths();
    this.generateexpiryDateYears();
  }

  state = {
    adding: false,
    loading: false,
    editing: false,
    errors: [],
    accounts: [],
    error: false
  }

  fetch(customerID) {
    let { accountToken } = this.props.router.params;

    axios
      .get(process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/' + customerID)
      .then(({data: accounts}) => {
        let account = accounts.find(x => x.accountToken === accountToken)
        if (account) {
          this.beginEdit(account)
        }
      })
  }

  componentDidMount() {
    if (this.props.customer.customer) {
      this.fetch(this.props.customer.customer.customerID)
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.customer.customer !== prevProps.customer.customer) {
      this.fetch(this.props.customer.customer.customerID)
    }
  }

  generateexpiryDateMonths() {
    let months = []
    // let monthNames = ["January", "February", "March", "April", "May", "June",
    // "July", "August", "September", "October", "November", "December" ]
    for (let i = 1; i <= 12; i++) {
      months.push({
        text: i,
        /*monthNames[i-1]*/
        value: i
      })
    }
    this.months = months
  }
  generateexpiryDateYears() {
    let years = []

    for (let i = this.currentYear - 2000; i <= (this.currentYear - 2000) + 10; i++) {
      years.push({
        text: i + 2000,
        value: i
      })
    }
    this.years = years
  }

  beginEdit = (account) => {
    let {cardholderName, expiryDateMonth, expiryDateYear, accountToken} = account

    this.setState({
      editing: {
        accountToken,
        cardholderName,
        defaultAccount: account['default'],
        expiryDateMonth: parseInt(expiryDateMonth, 10),
        expiryDateYear: parseInt(expiryDateYear, 10)
      }
    })

  }

  saveEdit = async (fields) => {
    const { customer } = this.props.customer

    if (!customer)
      return;

    Log.log('save edit!', fields)

    this.setState({ loading: true })

    axios.patch(`${process.env.REACT_APP_API_BASE_URI}paymentAccounts/${this.state.editing.accountToken}/${customer.customerID}`, {
      ...fields
    })
    .then(() => {
      this
        .props
        .router
        .navigate(-1)
    })
    .catch((err) => {
      if(err.response.status === 503) {
        this.setState({error: "Invalid Expiry Date."});
      }
      else {
        this.setState({error: "Unknown Error"});
      }
      this.setState({ loading: false });
    })
  }

  removeCard = async accountToken => {
    const { customer } = this.props.customer;

    if (!customer)
      return;

    this.setState({ loading: true, confirmDelete: false })

    await axios.delete(
      `${process.env.REACT_APP_API_BASE_URI}paymentAccounts/${this.state.editing.accountToken}/${customer.customerID}`
    );

    this.setState({ loading: false })
    this.props.router.navigate(-1)
  }

  render() {
    return (
      <Layout pageTitle="Card details">
        <Header size="large" textAlign="left">Edit Card</Header>
        <Modal
          name="payment-settings/edit/error"
          size="tiny"
          open={!!this.state.error}
          onClose={x => this.setState({error: false})}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{this.state.error}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={x => this.setState({error: false})}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Confirm
        open={this.state.confirmDelete}
        content="Are you sure you want to delete this card from your saved payment options?"
        cancelButton="Cancel"
        confirmButton={{
          content: "Delete Card",
          className: "confirm-button" // Adding a custom class
        }}
        onCancel={() => this.setState({ confirmDelete: false })}
        onConfirm={() => this.removeCard()}
        />
        <Dimmer inverted active={this.state.loading}>
          <Loader/>
        </Dimmer>

        { this.state.editing && <Form
          noValidate
          onValidSubmit={this.saveEdit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}>
          <ValidatedInput
            required
            label="Cardholder Name"
            name="cardholderName"
            value={this.state.editing.cardholderName}
            validations={{
              "maxLength": 100
            }}/>
          <Form.Group widths='equal'>
            <ValidatedDropdown
              required
              validations="isNumeric"
              options={this.months}
              label="Expiry Month"
              name="expiryDateMonth"
              value={this.state.editing.expiryDateMonth}/>
            <ValidatedDropdown
              required
              validations={{
              "isNumeric": true,
              "min": this.currentYear - 2000,
              "max": (this.currentYear - 2000) + 10
            }}
              options={this.years}
              label="Expiry Year"
              name="expiryDateYear"
              value={this.state.editing.expiryDateYear}/>

          </Form.Group>
          <ValidatedCheckbox
              name="defaultAccount"
              label="Primary"
              checked={this.state.editing.defaultAccount}/>
          <Form.Button fluid secondary type="submit">Save</Form.Button>
          <Form.Field>
            <Button
              type="button"
              onClick={x => this
              .props
              .router
              .navigate(-1)}
              fluid
              basic>Cancel</Button>
          </Form.Field>
          <Form.Button
            type="button"
            fluid
            basic
            primary
            onClick={x => this.setState({confirmDelete: true})}>Delete this card</Form.Button>

        </Form>}
      </Layout>
    )
  }
}

export default withRouter(withCustomer(PaymentSettingsEdit))
