import { Form } from "formsy-semantic-ui-react";
import { Button, Card, Modal } from "semantic-ui-react";
import ValidatedDropdown from "../Forms/ValidatedDropdown";
import { useState } from "react";
import { FORM_STATE } from "../../utils/FormState";
import PowerHoursIcon from "../Icons/PowerHoursIcon";
import PowerHoursBannerLogo from "../Icons/PowerHoursBannerLogo";
import InfoIcon from '../Icons/InfoIcon';
import './PowerHoursCard.css';
import { useEffect } from "react";  
import moment from "moment-timezone";
import { withApp } from "../../contexts/App";
import { useNavigate } from "react-router-dom";
import { useTheme } from '../../contexts/Theme'
import { SolidCard, SolidCardHeader, SolidCardContent } from "../../components/SolidCard/SolidCard"
import ExternalLink from '../ExternalLink';
import Urls from '../../utils/Urls';
import Analytics from '../../utils/Analytics';
export default withApp(({ powerHours, premises, onSubmit, onSubmitChange, hero, upcomingActiveOnly, app, style, ...cardProps }) => {

	const navigate = useNavigate();
	const { theme } = useTheme()

	const [timeRemaining, setTimeRemaining] = useState("");
	const [timerTarget, setTimertarget] = useState(null);
	const [timerTimeout, setTimerTimeout] = useState(null);


	useEffect(() => {		
		if (!powerHours.isValid) return;

		if (timerTimeout) return;


		let hasFinished = powerHours.hasFinished();
		let isInProgress = powerHours.isInProgress();

		let waitTime = null;

		let now = moment.now();

	
		if (!hasFinished) {						

			if (isInProgress) {
				setTimeRemaining(powerHours.getTimeRemaining());
				waitTime = 1000;
			} else if (!powerHours.isExpired) {
				if (powerHours.acceptedTimeSlot) {
					waitTime = powerHours.acceptedTimeSlot.startDateTime.valueOf() - now + 50;
				} else {
					let waitTimes = powerHours.availableTimeSlots.map(timeSlot => {
						return timeSlot.expiry.valueOf() - now + 50
					}).filter(waitTime => waitTime > 0);
					waitTimes.sort((a, b) => a - b);
					if (waitTimes.length > 0) {
						waitTime = waitTimes[0];
					} else {
						// The event has just expired. Should refresh entire page
						navigate(0);
					}
				}

			}
		}

		
		if (waitTime) {
			setTimertarget(now + waitTime);
		}


	}, [powerHours, timerTimeout]);


	useEffect(() => {
		// Set a timer, then clear timerTimeout afterwards so the main useEffect will run again to set a new timerTarget
		let waitTime = timerTarget - moment.now();

		clearTimeout(timerTimeout);
		if (timerTarget > 0) {
			setTimerTimeout(setTimeout(() => {
				setTimerTimeout(null);
			}, waitTime));
		}

	}, [timerTarget]);


	useEffect(() => {
		if (timerTimeout) {
			clearTimeout(timerTimeout);
			setTimerTimeout(null);
		}
	}, []);


	function renderContent() {
		if (!powerHours.isValid) return null;

		let hasFinished = powerHours.hasFinished();
		let isInProgress = powerHours.isInProgress();

		if (powerHours.isInvitation) {
			if (upcomingActiveOnly) return null;
			if (powerHours.invitationHasExpired) {
				return <ExpiredContent powerHours={powerHours} theme={theme} />
			} else {
				return <InvitationContent powerHours={powerHours} premises={premises} onSubmit={onSubmit} theme={theme} />
			}
		} else if (powerHours.isAccepted) { 
			let premise = premises.find(p => p.serviceAgreementID === powerHours.accountId) || premises[0];
			if (!premise) return null;
			if (hasFinished) {
				if (upcomingActiveOnly) return null;
				return <HistoricContent powerHours={powerHours} premise={premise} theme={theme} />
			} else if (isInProgress) {
				return <InProgressContent powerHours={powerHours} premise={premise} timeRemaining={timeRemaining} hero={hero} theme={theme} />
			} else {
				return <ConfirmedContent powerHours={powerHours} premise={premise} premises={premises} onSubmit={onSubmitChange} hero={hero} theme={theme} />
			}
		}

	}

	let content = renderContent();

	return (content ? 
		<SolidCard
			key={powerHours.id + powerHours.name}
			className={"power-hours-card" + (hero ? " hero" : "")}
			style={{...theme.card.default, ...style}}
			noContentPadding={!hero}
			{...cardProps}
		>
			{ !hero &&
				<SolidCardHeader>
					{powerHours.name}
				</SolidCardHeader>
			}

			{ content }		
		</SolidCard>
	: null);
});


function InfoPanel({ powerHours, address, ...props }) {
	return <Panel className="info-panel" {...props}>
		<PanelValue className="address">{address}</PanelValue>

		{ powerHours.isMultiDay() && powerHours.hasDuration() ?
			<PanelValue>{powerHours.renderDuration()}</PanelValue>
		:
			<>
				<PanelValue>{powerHours.getFormattedStartDate()}</PanelValue>
				<PanelValue>{powerHours.renderStartTime()} — {powerHours.renderEndTime()}</PanelValue>
			</>
		}
	</Panel>
}

function HistoricContent({ powerHours, premise, theme }) {

	const [showingPendingModal, setShowingPendingModal] = useState(false);
	const [showingActualsModal, setShowingActualsModal] = useState(false);
	const [showingSubstituteModal, setShowingSubstituteModal] = useState(false);
	const [showingNoUsageModal, setShowingNoUsageModal] = useState(false);

	let address = premise ? premise.formattedAddress : null;

	let showUsage = (powerHours.usage > 0) && !powerHours.hasSubstitute;

	function closeModal() {
		setShowingPendingModal(false);
		setShowingActualsModal(false);
		setShowingSubstituteModal(false);
		setShowingNoUsageModal(false);
	}	

	return (<>
		<Modal
			name="powerhours/pending"
			size="tiny"
			open={showingPendingModal}
			onClose={closeModal}
			style={{
				color: theme.modal?.color, 
				backgroundColor: theme.modal?.backgroundColor,
			}}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description >
					As soon as we receive your usage data, we will calculate your total savings for the Power Hours period and display it here. This will not appear immediately in your aurora+ balance, so look for it as a Power Hours credit on your bill statement.
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/actuals"
			size="tiny"
			open={showingActualsModal}
			onClose={closeModal}
			style={{
				color: theme.modal?.color, 
				backgroundColor: theme.modal?.backgroundColor,
			}}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					Your Total Savings is calculated on your usage during the Power Hours time slot. 
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/substitute"
			size="tiny"
			open={showingSubstituteModal}
			onClose={closeModal}
			style={{
				color: theme.modal?.color, 
				backgroundColor: theme.modal?.backgroundColor,
			}}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					We couldn't get a confirmed meter read within 30 days of this Power Hours time slot, so we have credited savings based on the maximum usage for a typical residential household.
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>

		<Modal
			name="powerhours/no-usage"
			size="tiny"
			open={showingNoUsageModal}
			onClose={closeModal}
			style={{
				color: theme.modal?.color, 
				backgroundColor: theme.modal?.backgroundColor,
			}}>
			<Modal.Header>Total Save</Modal.Header>
			<Modal.Content>
				<Modal.Description style={{ textAlign: "left" }}>
					There was no recorded usage for this time slot. <br />
					This can happen if:
					<ul>
						<li>you are a solar customer and were using all of your own generated power</li>
						<li>there was a power outage, or</li>
						<li>your meter is faulty.</li>
					</ul>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>


		<SolidCardContent className="historic-content">
			<Panels>
				<InfoPanel borderBottom powerHours={powerHours} address={address} />
				<Panel left={showUsage}>
					<PanelLabel>
						{ !!(!powerHours.hasSubstitute && powerHours.cost) &&
							<InfoIcon
								height="24px"
								style={{
									position: 'absolute',
									right: '10px',
									top: '11px',
									fill: '#002963',
								}}
								onClick={() => { setShowingActualsModal(true) }}
							/>
						}	
						{ !!(!powerHours.cost && (powerHours.cost !== 0)) &&
							<InfoIcon
								height="24px"
								style={{
									position: 'absolute',
									right: '10px',
									top: '11px',
									fill: '#002963',
								}}
								onClick={() => { setShowingPendingModal(true) }}
							/>
						}	
						Savings
					</PanelLabel>

					{
						powerHours.isFlagged ? (
							<PanelValue className="pending">Pending...</PanelValue>
						) : powerHours.cost ? (
							<>
								<PanelValue huge>
									{powerHours.renderCost()}
								</PanelValue>
								{powerHours.hasSubstitute &&
									<PanelValue small>
										Estimated data
										<br />
										<a style={{ textDecoration: 'underline', lineHeight: 2 }} onClick={() => { setShowingSubstituteModal(true); }}>
											Find out why
										</a>
									</PanelValue>
								}
							</>
						) : powerHours.cost === 0 ? (
							<>
								<PanelValue small>
									You had no recorded usage for this time slot
									<br />
									<a style={{ textDecoration: 'underline', lineHeight: 2 }} onClick={() => { setShowingNoUsageModal(true); }}>
										Find out why
									</a>
								</PanelValue>
							</>
						) : (
							<PanelValue className="pending">Pending...</PanelValue>
						)
					}
				</Panel>
				{ showUsage &&
					<Panel right>
						<PanelLabel>Usage</PanelLabel>
						<PanelValue huge>{powerHours.usage.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</PanelValue>
						<PanelValue small>kWh</PanelValue>
					</Panel>
				}
				<Panel borderTop style={{height: '20px', padding: 0}}></Panel>
			</Panels>

		</SolidCardContent>
	</>);
}



function ConfirmedContent({ powerHours, premise, premises, onSubmit, hero, theme }) {
	const defaultTimeslot = (powerHours.availableTimeSlots.length === 1) ? powerHours.availableTimeSlots[0] : null;
	const [confirmedContentEdit, setConfirmedContentEdit] = useState(false);
	const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);
	const [canSubmit, setCanSubmit] = useState(false);
	const [showTimeWarningModal, setShowTimeWarningModal] = useState(false);
	const [selectedPropertyId, setSelectedPropertyId] = useState(null);
	const [showStartedModal, setShowStartedModal] = useState(false);
	const navigate = useNavigate();

	const handleChangeTimeSlot = (e) => {
		e.preventDefault();
		
		// Check if current time slot has started
		const now = moment();
		if (powerHours.acceptedTimeSlot && moment(powerHours.acceptedTimeSlot.startDateTime) <= now) {
			setShowStartedModal(true);
			return;
		}

		setConfirmedContentEdit(true);
	};

	let address = premise ? premise.formattedAddress : null;
	let now = new moment();
	let timeSlotValue = selectedTimeSlotId || ''
	let validTimeSlots = powerHours.availableTimeSlots.filter(timeslot => {

		if (timeslot.startDateTime > now) {
			return true;
		} else {
			if (timeslot.id === timeSlotValue) {
				timeSlotValue = '';
			}
			return false;
		}
	});

	function onFormValid() {
		setCanSubmit(true);
	}

	function onFormInvalid() {
		setCanSubmit(false);
	}

	function onValidSubmit(payload) {
		if (premises.length === 1) payload.property = premises[0].parentAccountID;
			
		payload.powerHours = powerHours;
		payload.selectedTimeslot = powerHours.availableTimeSlots.find(ts => ts.id === payload.timeslot);
		payload.premise = premises.find(p => p.parentAccountID === payload.property);

		// Log GTM event data
		//console.log('GTM Event:', {
		//	category: 'Power Hours',
		//	action: 'Change Time Slot',
		//	value: `${powerHours.id}|${powerHours.acceptedTimeSlot.id}|${payload.selectedTimeslot.id}`
		//});

		// Track the time slot change event
		Analytics.event({
			category: 'Power Hours',
			action: 'Change Time Slot',
			value: `${powerHours.id}|${powerHours.acceptedTimeSlot.id}|${payload.selectedTimeslot.id}`
		});

		if (onSubmit) onSubmit(payload);
	}

	if (hero) {
		return (
			<HeroContent powerHours={powerHours} premise={premise} showTimeRemaining={false} theme={theme} />
		);
	} else {
		return (
			<>
				<Modal
					size='tiny'
					open={showTimeWarningModal}
					onClose={() => setShowTimeWarningModal(false)}
					style={{ color: theme.modal?.color, backgroundColor: theme.modal?.backgroundColor }}
				>
					<Modal.Header>Cannot change time slot</Modal.Header>
					<Modal.Content>
						<Modal.Description>
						 This time slot can not be changed as it is within 5 minutes of the start time.
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button
							color="orange"
							secondary
							fluid
							onClick={() => setShowTimeWarningModal(false)}
						>
							OK
						</Button>
					</Modal.Actions>
				</Modal>

				<Modal
					size='tiny'
					open={showStartedModal}
					onClose={() => {
						setShowStartedModal(false);
						navigate(0);
					}}
					style={{ color: theme.modal?.color, backgroundColor: theme.modal?.backgroundColor }}
				>
					<Modal.Header>Cannot change time slot</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							This time slot can not be changed as it has already started.
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button
							color="orange"
							secondary
							fluid
							onClick={() => {
								setShowStartedModal(false);
								navigate(0);
							}}
						>
							OK
						</Button>
					</Modal.Actions>
				</Modal>

				<SolidCardContent className="confirmed-content">
					{confirmedContentEdit ? (
						<Form
							noValidate
							onValidSubmit={onValidSubmit}
							onValid={onFormValid}
							onInvalid={onFormInvalid}
						>							
							<Panel>	
								<PanelPowerIcon />
								<PanelValue huge>Select new time slot</PanelValue>
							</Panel>
							{ (premises.length === 1) ?
								<div className="single-premise">{premises[0].formattedAddress}</div>
							:
								<ValidatedDropdown
									selection
									required
									options={[
										{
											key: 'placeholder',
											value: '',
											text: 'Select property',
											disabled: true
										}
									].concat(premises.map(premise => {
										return {
											key: premise.parentAccountID,
											value: premise.parentAccountID,
											text: premise.formattedAddress,
										};
									}))}
									name="property"
									placeholder="Select property"
									value={selectedPropertyId || ''}
									onChange={(event, { value }) => {
										if (value !== '') {
											setSelectedPropertyId(value);
										}
									}}
									error={false}
									style={{
										backgroundColor: theme.dropdown.backgroundColor,
										color: theme.dropdown.color,
										borderColor: theme.dropdown.borderColor,
										'--theme-dropdown-menu-background': theme.dropdown.menu.backgroundColor,
										'--theme-dropdown-menu-color': theme.dropdown.menu.color,
										'--theme-dropdown-menu-border': theme.dropdown.menu.borderColor,
										'--theme-dropdown-menu-hover': theme.dropdown.menu.hoverBackground,
										'--theme-dropdown-menu-selected-background': theme.dropdown.menu.selectedBackground,
										'--theme-dropdown-menu-selected-color': theme.dropdown.menu.selectedColor,
										'--theme-dropdown-menu-disabled-background': theme.dropdown.menu.disabledBackground,
										'--theme-dropdown-menu-disabled-color': theme.dropdown.menu.disabledColor,
										'--theme-dropdown-menu-shadow': theme.dropdown.menu.boxShadow
									}}
									className="themed-dropdown"
								/>
							}
							<ValidatedDropdown
								selection
								required
								options={[{
									'value': '',
									'text': 'Scroll to select time slot',
									'disabled': true
								}].concat(validTimeSlots.map(timeslot => {
									return {
										value: timeslot.id,
										text: timeslot.hasDuration() 
											? timeslot.renderDuration()
											: `${timeslot.startDateTime.format('ddd D MMM')} ${timeslot.startDateTime.format('h:mm A')} — ${timeslot.endDateTime.format('h:mm A')}`
									};
								}))}
								name="timeslot"
								placeholder="Select time slot"
								defaultValue={defaultTimeslot?.id}
								value={timeSlotValue}
								onChange={(event, { value }) => {
									setSelectedTimeSlotId(value);
								}}
								style={{
									backgroundColor: theme.dropdown.backgroundColor,
									color: theme.dropdown.color,
									borderColor: theme.dropdown.borderColor,
									'--theme-dropdown-menu-background': theme.dropdown.menu.backgroundColor,
									'--theme-dropdown-menu-color': theme.dropdown.menu.color,
									'--theme-dropdown-menu-border': theme.dropdown.menu.borderColor,
									'--theme-dropdown-menu-hover': theme.dropdown.menu.hoverBackground,
									'--theme-dropdown-menu-selected-background': theme.dropdown.menu.selectedBackground,
									'--theme-dropdown-menu-selected-color': theme.dropdown.menu.selectedColor,
									'--theme-dropdown-menu-disabled-background': theme.dropdown.menu.disabledBackground,
									'--theme-dropdown-menu-disabled-color': theme.dropdown.menu.disabledColor,
									'--theme-dropdown-menu-shadow': theme.dropdown.menu.boxShadow
								}}
								className="themed-dropdown"
							/>
							<span className="existingTimeSlot">Your existing time slot:</span>
							<InfoPanel powerHours={powerHours} address={address} />
							
								<Button 
								color="orange"
								secondary
								fluid>
								Lock in new time slot
								</Button>

								<Button 
								onClick={() => setConfirmedContentEdit(false)}
								style={{marginTop:"10px"}}
								>
									Keep existing time slot
								</Button>

						</Form>
					) : (
						<>
							<Panel borderBottom>
								<PanelPowerIcon />
								<PanelLabel>Time Slot</PanelLabel>
								<PanelValue huge>Confirmed</PanelValue>
							</Panel>
							<InfoPanel powerHours={powerHours} address={address} />
							<div className="edit-panel">
								<a href="#" onClick={handleChangeTimeSlot}>
									Change time slot
								</a>
							</div>
						</>
					)}
				</SolidCardContent>
			</>
		);
	}
}



function InProgressContent({ powerHours, premise, timeRemaining, hero, theme }) {

	let address = premise ? premise.formattedAddress : null;

	if (hero) {
		return (
			<HeroContent powerHours={powerHours} premise={premise} showTimeRemaining={true} timeRemaining={timeRemaining} theme={theme} />
		);

	} else {

		return (
			<SolidCardContent className="in-progress-content">
				<Panels>
					<Panel borderBottom>
						<PanelPowerIcon />
						<PanelLabel>Time Remaining</PanelLabel>
						<PanelValue huge>{timeRemaining}</PanelValue>
					</Panel>
					<InfoPanel powerHours={powerHours} address={address} />
				</Panels>
			</SolidCardContent>
		);
	}

}



function ExpiredContent({ powerHours }) {
	return (
		<SolidCardContent className="expired-content">
			<PanelValue>
				<p>This offer expired on {powerHours.getFormattedExpiry()}.</p>
				<p>Keep your eye out for the next Power Hours event and lock your time slot in early.</p>
			</PanelValue>				
		</SolidCardContent>
	);
}

function InvitationContent({ powerHours, premises, onSubmit }) {
	const { theme } = useTheme();
	const defaultTimeslot = (powerHours.availableTimeSlots.length === 1) ? powerHours.availableTimeSlots[0] : null;
	const [canSubmit, setCanSubmit] = useState(false);
	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);
	const [selectedPropertyId, setSelectedPropertyId] = useState(null);
	const [showStartTimeModal, setShowStartTimeModal] = useState(false);
	const navigate = useNavigate();

	function onFormValid() {
		setCanSubmit(true);
	}

	function onFormInvalid() {
		setCanSubmit(false);
	}

	function onValidSubmit(payload) {
		// If only one premises, hide the dropdown and add it to the payload here
		if (premises.length === 1) payload.property = premises[0].parentAccountID;
		
		payload.powerHours = powerHours;
		payload.selectedTimeslot = powerHours.availableTimeSlots.find(ts => ts.id === payload.timeslot);
		payload.premises = premises.find(p => p.parentAccountID === payload.property);

		if (onSubmit) onSubmit(payload);
	}

	// Add this function to check if timeslot is within 5 minutes
	const isWithinFiveMinutes = (timeslot) => {
		const now = moment();
		const startTime = moment(timeslot.startDateTime);
		const minutesUntilStart = startTime.diff(now, 'minutes');
		return minutesUntilStart < 5 && minutesUntilStart > 0;
	};

	// Filter the available timeslots
	let validTimeSlots = powerHours.availableTimeSlots.filter(timeslot => {
		const now = moment();
		
		// If timeslot has started, remove it
		if (moment(timeslot.startDateTime) <= now) {
			return false;
		}
		
		// If within 5 minutes and not selected, remove it
		if (isWithinFiveMinutes(timeslot) && timeslot.id !== selectedTimeSlotId) {
			return false;
		}
		
		return true;
	});

	// Check if selected timeslot has started
	useEffect(() => {
		if (selectedTimeSlotId) {
			const selectedSlot = powerHours.availableTimeSlots.find(ts => ts.id === selectedTimeSlotId);
			if (selectedSlot && moment(selectedSlot.startDateTime) <= moment()) {
				setShowStartTimeModal(true);
			}
		}
	}, [selectedTimeSlotId]);

	// Add modal for start time notification
	const StartTimeModal = () => (
		<Modal
			size="tiny"
			open={showStartTimeModal}
			onClose={() => {
				setShowStartTimeModal(false);
				navigate(0); // Refresh the page
			}}
		>
			<Modal.Header>Cannot select  time slot</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					This timeslot cannot be selected as it has started.
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={() => {
						setShowStartTimeModal(false);
						navigate(0); // Refresh the page
					}}
				>
					OK
				</Button>
			</Modal.Actions>
		</Modal>
	);

	return (
		<SolidCardContent className="invitation-content">
			<Form
				noValidate
				onValidSubmit={onValidSubmit}
				onValid={onFormValid}
				onInvalid={onFormInvalid}
			>

				{ (premises.length === 1) ?
					<div className="single-premise">{premises[0].formattedAddress}</div>
				:
					<ValidatedDropdown
						selection
						required
						options={[
							{
								key: 'placeholder',
								value: '',
								text: 'Select property',
								disabled: true
							}
						].concat(premises.map(premise => ({
							key: premise.parentAccountID,
							value: premise.parentAccountID,
							text: premise.formattedAddress,
						})))}
						name="property"
						placeholder="Select property"
						value={selectedPropertyId || ''}
						onChange={(event, { value }) => {
							if (value !== '') {
								setSelectedPropertyId(value);
							}
						}}
						error={false}
						style={{
							backgroundColor: theme.dropdown.backgroundColor,
							color: theme.dropdown.color,
							borderColor: theme.dropdown.borderColor,
							'--theme-dropdown-menu-background': theme.dropdown.menu.backgroundColor,
							'--theme-dropdown-menu-color': theme.dropdown.menu.color,
							'--theme-dropdown-menu-border': theme.dropdown.menu.borderColor,
							'--theme-dropdown-menu-hover': theme.dropdown.menu.hoverBackground,
							'--theme-dropdown-menu-selected-background': theme.dropdown.menu.selectedBackground,
							'--theme-dropdown-menu-selected-color': theme.dropdown.menu.selectedColor,
							'--theme-dropdown-menu-disabled-background': theme.dropdown.menu.disabledBackground,
							'--theme-dropdown-menu-disabled-color': theme.dropdown.menu.disabledColor,
							'--theme-dropdown-menu-shadow': theme.dropdown.menu.boxShadow
						}}
						className="themed-dropdown"
					/>
				}

				<ValidatedDropdown
					selection
					required
					options={[{
						'value': '',
						'text': 'Scroll to select time slot',
						'disabled': true
					}].concat(validTimeSlots.map(timeslot => {
						return {
							value: timeslot.id,
							text: timeslot.hasDuration() 
								? timeslot.renderDuration()
								: `${timeslot.startDateTime.format('ddd D MMM')} ${timeslot.startDateTime.format('h:mm A')} — ${timeslot.endDateTime.format('h:mm A')}`
						};
					}))}
					name="timeslot"
					placeholder="Select time slot"
					defaultValue={defaultTimeslot?.id}
					value={selectedTimeSlotId || ''}
					onChange={(event, { value }) => {
						setSelectedTimeSlotId(value);
					}}
					style={{
						backgroundColor: theme.dropdown.backgroundColor,
						color: theme.dropdown.color,
						borderColor: theme.dropdown.borderColor
					}}
					className="themed-dropdown"
				/>

				<Button
					type="submit"
					fluid
					secondary
					disabled={!canSubmit || !onSubmit}          
					loading={formState === FORM_STATE.LOADING}
				>Lock in time slot</Button>
			</Form>
			<StartTimeModal />
		</SolidCardContent>
	);
}


function HeroContent({ powerHours, premise, showTimeRemaining, timeRemaining, theme }) {
	// Shared layout for the homepage version of InProgress and Confirmed

	let address = premise ? premise.formattedAddress : null;

	return (<>
		<SolidCardHeader>
			<div>
				<div className="event-name">{powerHours.name}</div>
				<div className="day">{powerHours.getFormattedStartDate()}</div>
			</div>
			<PowerHoursBannerLogo width={90} height={'auto'} />
		</SolidCardHeader>

		<SolidCardContent className="powerhours-hero-content">

			<div className="time-columns">
				{ powerHours.hasDuration() ? <>
					<div className="wide">
						Duration
						<div className="duration">{powerHours.renderDuration()}</div>
					</div>
				</> : <>
					<div>
						Start
						<div className="time">{powerHours.renderStartTime()}</div>
					</div>
					<div style={{borderLeft: theme.divider}}>
						Finish
						<div className="time">{powerHours.renderEndTime()}</div>
					</div>
				</>}
				{ showTimeRemaining && <div style={{borderLeft: theme.divider}}>
					Time Remaining
					<div className="time remaining">{timeRemaining}</div>
				</div> }
			</div>

			<div className="address">{address}</div>

		</SolidCardContent>

	</>);

}

export const PowerHoursSummary = ({ cost, usage, hours}) => {
	const { theme } = useTheme(); 
	const [showingTotalsModal, setShowingTotalsModal] = useState(false);
	
	function closeModal() {
		setShowingTotalsModal(false);
	}

	return <>

		<Modal
			name="powerhours/summary/totals"
			size="tiny"
			open={showingTotalsModal}
			onClose={closeModal}
			style={{
				color: theme.modal?.color, 
				backgroundColor: theme.modal?.backgroundColor,
			}}
			>
			<Modal.Header>Power Hours Summary</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					Information on your overall Power Hours usage and savings.
					<ul>
						<li>Total Savings: the amount of money you've saved throughout all Power Hours events.</li>
						<li>Total Power Hours: the total hours of free electricity that you've received during all Power Hours events.</li>
						<li>Total Usage: the total amount of kWh you've used during all Power Hours events.</li>
					</ul>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					color="orange"
					secondary
					fluid
					onClick={closeModal}
				>OK</Button>
			</Modal.Actions>
        </Modal>



	<Card
    className="power-hours-card power-hours-summary"
    style={{ color: theme.card.default?.color, backgroundColor: theme.card.default?.background }}
>
    <Card.Content>
		<div className="summary-header">
			<div className="summary-title"><span className="totals-header">Your total savings</span> </div>
			<div className="powerhourslogo"> <PowerHoursBannerLogo /></div>
		</div>
	
        <div className="summary-panels">
            <div className="summary-panel left">
                <div className="panel-value huge">
                    <span className="dollars">$</span>
                    {cost.toFixed(2)}
                </div>
            </div>

            <div className="summary-panel center">
                <div className="panel-value huge">{hours}</div>
                <div className="panel-value small">hrs</div>
            </div>

            <div className="summary-panel right">
                {usage > 0 ? (
                    <>
                        <div className="panel-value huge">
                            {usage.toLocaleString('en-AU', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </div>
                        <div className="panel-value small">kWh</div>
                    </>
                ) : (
                    <div className="panel-value small">
                        You had no<br />recorded usage
                    </div>
                )}
            </div>

            <div className="summary-info">
                <p>
				Choose a residential property and your preferred time slot. Any electricity you use in that time slot will be credited on a future bill. <ExternalLink 
				href={Urls.POWER_HOURS_INFO}
				style={{ color: theme.primary.color }}
				target="_blank"
				rel="noopener noreferrer"
			>
				More info
			</ExternalLink>
                </p>
            </div>
        </div>
    </Card.Content>
</Card>
	</>
}



function Panels({ children }) {
	return <div className="panels">{children}</div>
}
function Panel({ className, children, borderBottom, borderTop, left, right, hero, ...props }) {
	return <div className={
		[
			'panel',
			hero ? 'hero' : null,
			borderBottom ? 'border-bottom' : null,
			borderTop ? 'border-top' : null,
			left ? 'left' : null,
			right ? 'right' : null,
			className ? className : null,
		].filter(c => c !== null).join(" ")
	} {...props}>
		{children}
	</div>
}

function PanelPowerIcon() {
	return <div style={{
		textAlign: 'right',
		position: 'absolute',
		right: '10px',
		top: 'calc(50% - 16px)', // Icons 32px high by default
	}}>
		<PowerHoursIcon/>
	</div>
}

function PanelLabel({ children }) {
	return <div className="panel-label">{children}</div>
}
function PanelValue({ children, huge, large, medium, small, className }) {
	return <div className={"panel-value" + (className ? " " + className : "") + (huge ? " huge" : "") + (large ? " large" : "") + (medium ? " medium" : "") + (small ? " small" : "")}>{children}</div>
}

// First, let's add a function to check if a timeslot is within 5 minutes of starting
const isWithinFiveMinutes = (timeslot) => {
	const now = moment();
	const startTime = moment(timeslot.startDateTime);
	const minutesUntilStart = startTime.diff(now, 'minutes');
	return minutesUntilStart < 5 && minutesUntilStart > 0;
};