import moment from 'moment-timezone';


export default class Bill {

  constructor(data) {
    this.billId = data.BillId;
    this.billIdToPay = data.BillIdToPay;
    this.billPeriodDateEnd = data.BillPeriodDateEnd;
    this.billPeriodDateStart = data.BillPeriodDateStart;
    this.amountOutstanding = data.AmountOutstanding;
    this.closingBalance = data.ClosingBalance;
    this.dateBilled = data.DateBilled;
    this.dateComplete = data.DateComplete;
    this.dateDue = data.DateDue;
    this.statementAvailable = data.StatementAvailable;
    this.invoiceDocNumber = data.InvoiceDocNumber;
  }

  get isValid() {
    // PDF statements become available after a maximum of 2 days delay
    // DateDue is null in some circumstances, such as when the bill has been replaced with a payment extension
    //DPS-138, Bills that have the prefix of CCB in their doc number don't have valid PDFs but we want to show them anyway
    return (this.statementAvailable || (!this.statementAvailable & this.invoiceDocNumber.includes('CCB'))) && !!this.dateDue;
  }

  get isOverdue() {
    return moment(this.dateDue).diff(moment(), "days") < 0;
  }

}
