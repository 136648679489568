import React from 'react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import {
  List,
  Label,
  Button,
  Header,
  Grid,
  Dimmer,
  Loader,
  Card,
  Responsive,
  Tab,
  Menu,
  Radio
} from 'semantic-ui-react';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';
import { Link } from 'react-router-dom';
import { Form } from 'formsy-semantic-ui-react';
import ValidatedInput from '../../components/Forms/ValidatedInput';
import ValidatedInputWithSelect from '../../components/Forms/ValidatedInputWithSelect';
import ValidatedCheckbox from '../../components/Forms/ValidatedCheckbox';

import Layout from '../../layouts/Layout';
import FullWidthLayout from '../../layouts/FullWidthLayout'
import ColumnLayout from '../../layouts/ColumnLayout';

import { withRouter } from '../../utils/useRouter';
import { withApp } from '../../contexts/App'
import { withCustomer } from '../../contexts/Customer'
import { withTheme, themes } from '../../contexts/Theme';
import { Modal } from "semantic-ui-react";
import {
  AutoTopupConfirmModal,
  AutoTopupSavedModal,
  AutoTopupStopModal,
  AutoTopupAddCardModal,
  UsingAutoTopupModal
} from './components/AutoTopupModals';
import {
  DirectDebitConfirmModal,
  DirectDebitSavedModal,
  DirectDebitStopModal,
  UsingDirectDebitModal
} from './components/DirectDebitModals';
import { UsingTopupModal } from './components/TopupModals';
import Log from '../../utils/Log';
import PaymentModals from './PaymentModals';
import MobileAppComms from '../../utils/MobileAppComms';
import { openBill } from '../../utils/PDF';
import Urls from '../../utils/Urls';
import BankDetails from '../../model/BankDetails';
import BillingDetails from '../../model/BillingDetails';
import ExternalLink from '../../components/ExternalLink';
import TickInCircleIcon from '../../components/Icons/TickInCircleIcon';
import AutoPaymentDetails from '../../model/AutoPaymentDetails';
import PaymentPlan, { PaymentPlanStatus } from '../../model/PaymentPlan';
import PaymentExtension from '../../model/PaymentExtension';
import PaymentAccount from '../../model/PaymentAccount';
import ActivePayment from '../../model/ActivePayment';
import InfoIcon from '../../components/Icons/InfoIcon';
import Analytics from '../../utils/Analytics';
import ValidatedInputWithAutocomplete from '../../components/Forms/ValidatedInputWithAutocomplete';

const HTTP_PAYMENT_SUCCEEDED_WITH_ERRORS = 512;


export class Payment extends React.Component {
  static defaultProps = {
    minAmount: process.env.NODE_ENV === 'production' ? 10 : 1,
    maxAmount: 10000
  }
  state = {
    isLoading: false,
    errors: {},
    amount: null,
    activePayment: null,
    selectedAccount: null,
    billingDetails: null,
    bankDetails: null,
    bankDetailsSaved: false,
    autoPaymentDetails: null,
    autoPaymentDetailsSaved: false,
    termsConditionsChecked: false,
    hasDirectDebit: false,
    paymentExtension: null,
    paymentPlan: null,
    displayNumberInstalments: 3,
    loadingPaymentPlan: false,
    savePaymentInformation: false,
    paymentSuccessful: false,
    payNowButtonText: 'Pay Now',
    restrictedNavigation: false,
    makePayment: false,
    autoTopupConfirmation: { open: false },
    autoTopupSaved: { open: false },
    autoTopupStop: { open: false },
    directDebitConfirmation: { open: false },
    directDebitSaved: { open: false },
    directDebitStop: { open: false },
    usingDirectDebit: { open: false },
    usingAutoTopup: { open: false },
    usingTopup: { open: false },
    autoTopupAddCard: { open: false },
    modalTermsAndConditions: false,
	  bankCodes: [],
    isReferenceModalOpen: false,
    accountNumber: '',
    accountNumberCheckDigit: ''
  }

  componentDidMount() {
    const { restrictedNavigation } = this.props;
    const { customerPremise } = this.props.customer;

    const modals = PaymentModals({
      closeModal: this.closeModal,
      restrictedNavigation
    });

    this.setState({ restrictedNavigation, ...modals, makePayment: false });
    this.fetchBankCodes();
    if (!customerPremise) return;

	
    // check if current customer already has a direct debit and/or auto-payment set up
    // of any bills to pay
    this.fetchBankDetails(customerPremise.parentAccountID);
    this.fetchAutoPaymentDetails(customerPremise.parentAccountID);
    this.fetchPaymentAccount();

    // get payment plan, extension and bills
    this.fetchAllDataRequired();

    this.setState({
      accountNumber: customerPremise?.parentAccountNumber || '',
      accountNumberCheckDigit: customerPremise?.parentAccountNumberCheckDigit || '',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerPremise } = this.props.customer;
    const { customerPremise: prevCustomerPremise } = prevProps.customer;

    if (!customerPremise) return;

    if (!prevCustomerPremise || prevCustomerPremise.serviceAgreementID !== customerPremise.serviceAgreementID) {
      this.setState({
        bankDetails: null,
        bankDetailsSaved: false,
        autoPaymentDetails: null,
        autoPaymentDetailsSaved: false
      });

      // check if current customer already has a direct debit and/or auto-payment set up
      // of any bills to pay
      this.fetchActivePayment();
      this.fetchBankDetails();
      this.fetchAutoPaymentDetails();
      this.fetchPaymentAccount();

      // get payment plan, extension and bills
      this.fetchAllDataRequired();
    }
  }

  fetchBankCodes = () => {
	return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment/bankcodes`)
      .then(res => {
		let bankCodes = null;
		if (res.data) {
			bankCodes = [];
			res.data.forEach(bank => {
				bankCodes.push({
					id: bank.DomainCode,
					name: bank.Description,
				})
			})
		}

        this.setState({
          bankCodes: bankCodes
        });
      })
      .catch(err => {
        this.setState({ bankCodes: null });
      })
  }

  fetchActivePayment = () => {
    const { customerPremise } = this.props.customer;

    if (!customerPremise) return;

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment/activepayment/${customerPremise.parentAccountID}`)
      .then(res => {
        const activePayment = new ActivePayment(res.data);

        this.setState({
          activePayment: activePayment
        });
      })
      .catch(err => {
        this.setState({ activePayment: null });
      })
  }

  fetchBillingDetails = () => {
    const { customerPremise } = this.props.customer;

    if (!customerPremise) return;

    if (customerPremise.numberOfUnpaidBills < 1) {
      this.setState({ billingDetails: null });
      return;
    }

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `bills/${customerPremise.parentAccountID}?parentAccountNumber=${customerPremise.parentAccountNumber}`)
      .then(res => {
        let data = res.data;

        if (data) {
          this.setState({
            billingDetails: new BillingDetails(data)
          });
          return;
        }

        this.setState({
          billingDetails: null
        });
      })
      .catch(res => {
        this.setState({ billingDetails: null });
      });
  }

  fetchPaymentPlan = () => {
    const { customer, customerPremise: premise } = this.props.customer;

    if (!premise || customer.isFromCCB || !premise.hasActivePaymentPlans) {

      if (this.state.paymentPlan) {
        this.setState({ paymentPlan: null });
      }
      return;
    }

    this.setState({ loadingPaymentPlan: true });

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${premise.parentAccountID}/plans`)
      .then(res => {
        const plan = new PaymentPlan(res.data);

        this.setState({
          paymentPlan: plan.hasStarted ? plan : null,
          displayNumberInstalments: 3,
          loadingPaymentPlan: false
        });

      })
      .catch(err => {
        this.setState({
          paymentPlan: null,
          displayNumberInstalments: 3,
          loadingPaymentPlan: false
        });
      })
  }

  fetchPaymentExtension = () => {
    const { customer, customerPremise: premise, pageConfig } = this.props.customer;

    if (!premise
      || !pageConfig.functionality.paymentExtension
      || !premise.hasActivePaymentExtension) {

      if (this.state.paymentExtension) {
        this.setState({ paymentExtension: null });
      }
      return;
    }

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment-assistance/${premise.parentAccountID}/extensions`)
      .then(res => {
        const extension = new PaymentExtension(res.data);

        this.setState({
          paymentExtension: extension.isActive ? extension : null
        });
      })
      .catch(err => {
        this.setState({ paymentExtension: null });
      })
  }

  fetchBankDetails = () => {
    const { customerPremise, pageConfig } = this.props.customer;
    
    if (!customerPremise || (pageConfig && !pageConfig.functionality.automaticPayments && !pageConfig.functionality.directDebit)) {

      this.setState({
        bankDetails: null,
        bankDetailsSaved: false
      });
      return;
    }

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment/directdebit/${customerPremise.parentAccountID}`)
      .then(res => {
        const data = res.data;

        if (!data) {
          this.setState({
            bankDetails: null,
            bankDetailsSaved: false
          });
          return;
        }

        data.BankAccountNumber = '***' + data.BankAccountNumber;

        this.setState({
          bankDetails: new BankDetails(data),
          bankDetailsSaved: true
        });
      })
      .catch(res => {
        this.setState({
          bankDetails: null,
          bankDetailsSaved: false
        });
        
        this.openModal('ErrorModal');
      });
  }

  fetchAutoPaymentDetails = () => {
    const { customerPremise, pageConfig } = this.props.customer;

    if (!customerPremise || (pageConfig && !pageConfig.functionality.automaticPayments)) {

      this.setState({
        autoPaymentDetails: null,
        autoPaymentDetailsSaved: false
      });
      return;
    }

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + `payment/${customerPremise.parentAccountID}/autopayment`)
      .then(res => {
        const data = res.data;

        const autoPayment = new AutoPaymentDetails(data);
        
        this.setState({
          autoPaymentDetails: autoPayment.isActive ? autoPayment : null,
          autoPaymentDetailsSaved: autoPayment.isActive
        });
      })
      .catch(res => {
        this.setState({
          autoPaymentDetails: null,
          autoPaymentDetailsSaved: false
        });
        
        this.openModal('ErrorModal');
      });
  }

  fetchPaymentAccount() {
    const { customer } = this.props.customer;
    
    this.setState({ loadingPrimaryAccount: true })

    return axios
      .get(process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/' + customer.customerID)
      .then(({ data }) => {
        if (!data) {
          this.setState({
            primaryAccount: null,
            loadingPrimaryAccount: false
          })
        }

        const primary = _.find(data, 'default');
        
        this.setState({
          primaryAccount: new PaymentAccount(primary),
          loadingPrimaryAccount: false
        })

      })
      .catch(() => {
        this.setState({
          primaryAccount: null,
          loadingPrimaryAccount: false
        })
      })
  }

  fetchAllDataRequired = () => {
    this.setState({ isLoading: true });

    // wait for all API requests to complete, before we return the promise
    return Promise
      .all([
        this.fetchPaymentPlan(),
        this.fetchPaymentExtension()
      ])
      .then(() => this.fetchBillingDetails())
      .then(() => this.setState({ isLoading: false }));
  }

  westpacPayment = null

  async attemptPayment(account, amount, billId = null) {
    let {customer, customerPremise} = this.props.customer
    const { accountIdOverride } = this.props;

    this.closeModal('mPaymentConfirm')
    this.closeModal('mPaymentConfirmPostPay')

    this.setState({processing: true, disabled: true, payNowButtonText: 'Processing Payment'})

    let data; //save the response to return at the end
    const accountId = accountIdOverride ? accountIdOverride : customerPremise.parentAccountID;

    try {
      //make the payment
      let response = await axios.post(process.env.REACT_APP_API_BASE_URI + 'payment', {
        Amount: amount,
        [account.tokenType + 'Token']: (account.accountToken),
        CustomerReference: customer.customerID,
        PaymentReference: accountId,
        InvoicedId: billId 
      })

      this.setState({
        paymentSuccessful: true,
        payNowButtonText: 'Payment Successful'
      })

      data = response.data;
      Analytics.event({category: 'Payment', action: 'Pay Now successful'})

    } catch (e) {
      const isSuccessWithErrorsResponse = e.response && e.response.status === HTTP_PAYMENT_SUCCEEDED_WITH_ERRORS;

      // continue as usual if we get a specific error code from the api
      if (isSuccessWithErrorsResponse) {
        data = e.response.data;
      } else {
        this.setState({payNowButtonText: 'Pay Now', processing: false})
        // eslint-disable-next-line
        throw {...e, code: 'PAYMENT'}
      }
    }

    try {
      //save the payment information if selected.
      if (this.paymentMethod.savePaymentInformation){
        await axios.post(process.env.REACT_APP_API_BASE_URI + 'paymentAccounts/registerPaymentAccount', {
          ReceiptNumber: data.ReceiptNumber,
          CustomerID: customer.customerID,
        })
      }
    } catch (e) {
      // eslint-disable-next-line
      throw {...e, code: 'CARD', TotalAmountDisplay: data.TotalAmountDisplay}
    } finally {
      this.setState({processing: false})
    }

    return data;
  }

  openModal = (prop, options = {}) => {
    this.setState({
      [prop]: {
        ...this.state[prop],
        ...options,
        open: true,
        render: true
      }
    })
  }

  closeModal = (...props) => {
    props.forEach(prop => this.setState({
      [prop]: {
        ...this.state[prop],
        open: false
      }
    }))

    if (this.props.onModalClose) this.props.onModalClose(props);
  }

  updateModal = (prop, props = {}) => {
    this.setState({
      [prop]: {
        ...this.state[prop],
        ...props
      }
    })
  }

  isModalOpen = (prop) => {
    return this.state[prop].open
  }

  confirmAutoTopUpModal = async (prop) => {
    const { customerPremise } = this.props.customer
    this.closeModal('autoTopupConfirmation')

    this.setState({ processing: true })

    Log.log('SUBMIT', this.state.amount)

    try {
      let response = await axios.post(process.env.REACT_APP_API_BASE_URI + `payment/${customerPremise.parentAccountID}/autopayment`, this.state.autoPaymentDetails)
      Analytics.event({category: 'Payment', action: 'Auto payments setup successful'})

      if (!response.data) {
        this.setState({
          autoPaymentDetailsSaved: false
        });
        return;
      }

      const autoPayment = new AutoPaymentDetails(response.data);

      this.setState({
        autoPaymentDetails: autoPayment.isActive ? autoPayment : null,
        autoPaymentDetailsSaved: autoPayment.isActive
      });

      this.openModal('autoTopupSaved')
    }
    catch (e) {
      this.setState({ autoPaymentDetailsSaved: false });
      
      this.openModal('ErrorModal');
    }
    finally {
      this.setState({ processing: false })
    }
  }

  autoTopupStop = () => {
    const { customerPremise } = this.props.customer
    this.closeModal('autoTopupStop')
    
    this.setState({ processing: true })

    axios
      .post(process.env.REACT_APP_API_BASE_URI + `payment/${customerPremise.parentAccountID}/autopayment`, { trigger : "DISABLED" })
      .then(() => {
        this.setState({
          processing: false,
          autoPaymentDetails: null,
          autoPaymentDetailsSaved: false
        })
      })
      .catch(() => {
        this.setState({ processing: false })
        
        this.openModal('ErrorModal');
      })
  }

  confirmDirectDebitModal = async (prop) => {
    this.closeModal('directDebitConfirmation')

    this.setState({ processing: true })

    try {
      const { customerPremise } = this.props.customer
      
      let response = await axios.post(process.env.REACT_APP_API_BASE_URI + `payment/directdebit/${customerPremise.parentAccountID}`, this.state.bankDetails)
      
      response.data.BankAccountNumber = '***' + response.data.BankAccountNumber;

      this.setState({
        bankDetails: new BankDetails(response.data),
        bankDetailsSaved: true
      });
      
      Analytics.event({category: 'Payment', action: 'Direct Debit setup successful'})

      this.openModal('directDebitSaved')

    } catch (e) {
      this.setState({ bankDetailsSaved: false });
      
      this.openModal('ErrorModal');
    } finally {
      this.setState({ processing: false })
    }    
  }

  directDebitStop = () => {
    const { customerPremise } = this.props.customer

    this.closeModal('directDebitStop')

    this.setState({ processing: true })

    axios
      .delete(process.env.REACT_APP_API_BASE_URI + `payment/directdebit/${customerPremise.parentAccountID}`)
      .then(() => {
        this.setState({
          processing: false,
          bankDetails: null,
          bankDetailsSaved: false
        })
      })
      .catch(() => {
        this.setState({ processing: false })
        
        this.openModal('ErrorModal');
      })
  }

  confirmPayment = async (paymentAccount, amount) => {
    
    const { theme } = this.props.theme;
    const { pageConfig, customerPremise } = this.props.customer;
    const themeStyle = {
      backgroundColor: theme.important.backgroundColor
    }
    const hasBills = pageConfig && pageConfig.functionality.billingType === "postPay";

    let payType = "";
    let paymentModalOptions = {
      name: 'mPaymentConfirm',
      billId: customerPremise.billIdToPay
    };

    // when there's a bill, we want to send bill details through (unless it's already paid and this is an ad-hoc payment)
    if (customerPremise.billTotalAmount > 0 && hasBills) {
      payType = "PostPay";
    }

    this.openModal(paymentModalOptions.name, {
      billId: paymentModalOptions.billId,
      account: paymentAccount,
      amount,
      style: themeStyle,
      onConfirm: () => {

        this.updateModal('mPaymentConfirm' + payType, { loading: true, amount })

        this.attemptPayment(paymentAccount, amount, paymentModalOptions.billId).then(response => {
          //check whether success or pending CCB processing
          if (response.Success) {
            this.openModal('mPaymentSuccess' + payType, { style: themeStyle, ...response })
          } else {
            this.openModal('mPaymentPending', { style: themeStyle, ...response })
          }

        }).catch(error => {
          if (error.code === 'CARD') {
            this.openModal('mPaymentSuccessCardSaveFailure', { style: themeStyle, TotalAmountDisplay: error.TotalAmountDisplay}/*, {error: {message: error.response.data}}*/)
          } else {
            this.openModal('mPaymentFailure', { style: themeStyle, amount}/*, {error: {message: error.response.data}}*/)
          }
        })
      }
    });

  }

  submitAutoTopup = (fields) => {
    this.setState({
      autoPaymentDetails: new AutoPaymentDetails(fields)
    });

    this.openModal('autoTopupConfirmation');
  }

  submit = async (fields) => {
    this.setState({ processing: true })

    try {
      let paymentAccount = await this
        .paymentMethod
        .getPaymentAccount();
      if (paymentAccount) {
        await this.confirmPayment(paymentAccount, this.state.selectedTopUpOption || fields.amount)
      }
    } finally {
      this.setState({ processing: false })
    }
  }

  onFormValid = (a) => {
    this.setState({canSubmit: true});
  }

  onFormInvalid = (a) => {
    this.setState({canSubmit: false});
  }

  renderPayNowForm = () => {
    const { BillSummary } = this
    const { restrictedNavigation, billingDetails } = this.state;
    const { theme } = this.props.theme;
    const { customer, pageConfig } = this.props.customer;

    const topUpOptions = [
      {
        value: 20,
        label: '$20'
      }, {
        value: 50,
        label: '$50'
      }, {
        value: 100,
        label: '$100'
      }
    ]

    return (
      <div className="payment-form">
        <Form
          className="topup-form"
          noValidate
          onValidSubmit={this.submit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}
          loading={this.state.processing}
        >
          {customer.isFromHubCX
            ? <BillSummary />
            : <Header style={{ color: 'rgba(0, 0, 0, 0.87)', padding: 0 }} size='large'>Pay Now</Header>
          }

          <Form.Field disabled={this.state.disabled}>
            <label>Set amount</label>

            <Grid centered columns={topUpOptions.length} className="topup-options">
              {topUpOptions.map(v => (
                <Grid.Column key={v.value}>
                  <Button
                    fluid
                    type="button"
                    color='grey'
                    onClick={ev => {
                      ev.preventDefault();
                      let newState = { selectedTopUpOption: null, amount: '' };

                      if (this.state.selectedTopUpOption !== v.value) {
                        newState = { selectedTopUpOption: v.value, amount: v.value }
                      }

                      this.setState(newState);
                    }}
                    value={v.value}
                    active={this.state.selectedTopUpOption === v.value}>
                    {v.label}
                  </Button>
                </Grid.Column>
              ))}
            </Grid>
          </Form.Field>

          <Form.Field className='required'>
            <label>Payment amount</label>
            <ValidatedInput
              required
              validations={{
                'isNumeric': true,
                'min': this.props.minAmount,
                'matchRegexp': /^\d*(\.\d{0,2})?$/
              }}
              validationErrors={{
                matchRegexp: 'Please enter no more than 2 decimal places',
                min: `Minimum payment amount of $${this.props.minAmount}`,
                isDefaultRequiredValue: `Minimum payment amount of $${this.props.minAmount}`
              }}
              name="amount"
              className="payment-other-value-input"
              type='number'
              icon="dollar"
              step="0.01"
              min={this.props.minAmount}
              fluid
              iconPosition="left"
              onKeyPress={e => ['+', '-', 'E', 'e'].indexOf(e.key) > -1 && e.preventDefault()}
              value={this.state.amount}
              disabled={!!this.state.selectedTopUpOption || this.state.disabled} />
          </Form.Field>

          {!restrictedNavigation && (
            <p>To see BPay details,{' '}
              <Link to="/accountSettings">go here</Link>
            </p>
          )}

          <Form.Field disabled={this.state.disabled} className='required'>
            <label>Payment method</label>
            <PaymentMethod
              ref={cmp => this.paymentMethod = cmp}
              restrictedNavigation={restrictedNavigation}
              customer={this.props.customer}
              onSelect={account => {
                this.setState({ selectedAccount: account })
              }}
              onError={x => this.setState({ error: true })}
              disabled={this.state.disabled} />
          </Form.Field>
          <Form.Field disabled={this.state.disabled}>
            <Button
              style={{ backgroundColor: theme.important.backgroundColor }}
              className="state-button"
              loading={this.state.processing}
              disabled={!this.state.canSubmit || this.state.paymentSuccessful}
              secondary
              fluid>
              {this.state.payNowButtonText}
            </Button>
          </Form.Field>
        </Form>
      </div>
    );
  }

  submitDirectDebit = (fields) => {

    this.setState({
      bankDetails: new BankDetails(fields)
    });

    this.openModal('directDebitConfirmation');
  }

  renderDirectDebit = () => {
    const { theme } = this.props.theme;
    const { bankDetails, bankDetailsSaved } = this.state;
    const { hasLoaded, customerPremise, pageConfig } = this.props.customer;
    const isResidentialCustomer = pageConfig && pageConfig.productName === "residential";
    const isBusinessCustomer = pageConfig && pageConfig.productName === "business";

    // if we have an extension or payment plan, don't allow direct debit changes (HubCX API limitation)
    const hasExtOrPlan = this.state.paymentExtension || this.state.paymentPlan;
    
    const bsbValidation = {
      isNumeric: true,
      minLength: 6,
      maxLength: 6
    }

    const SaveButton = () => (
      <React.Fragment>
        <Form.Field>
          <Button
            type='submit'
            style={{ backgroundColor: theme.important.backgroundColor }}
            secondary
            fluid
            //loading={this.state.formState === FORM_STATE.LOADING || this.state.processing?}
            disabled={!this.state.canSubmit || !this.state.termsConditionsChecked || !!hasExtOrPlan}>
            Save direct debit
          </Button>
        </Form.Field>
      </React.Fragment>
    );

    const UpdateButtons = () => (
      <React.Fragment>
        <Form.Field>
          <Button
            type='submit'
            style={{ backgroundColor: theme.important.backgroundColor }}
            secondary
            fluid
            disabled={!!hasExtOrPlan}
            onClick={() => this.setState({ bankDetails: null, bankDetailsSaved: false })}>
            Update direct debit
          </Button>
        </Form.Field>

        <Button
          onClick={() => this.openModal('directDebitStop')}
          style={{
            color: '#0060ae',
            background: 'none',
            boxShadow: `0 0 0 1px #0060ae inset`
          }}
          disabled={!!hasExtOrPlan}
          fluid>
          Stop direct debit</Button>
      </React.Fragment>
    );

    const ActiveHeader = () => (
      <div className="active-header">
        <Header as="h4">Direct debit is active</Header>
        <TickInCircleIcon color={ "#0060ae" } />
      </div>
    );

    const InactiveHeader = () => (
      <React.Fragment>
        <InfoIcon
          height="16px"
          style={{
            float: 'right',
            position: 'relative',
            zIndex: 50, 
            color: '#0060AE'
          }}
          onClick={() => this.openModal('usingDirectDebit')} />
        <Header
          as="h4"
          style={{ opacity: !!hasExtOrPlan && 0.8}}
        >Enter your direct debit details below to have future bill amounts paid in full by the due date</Header>
      </React.Fragment>
    );

    return (
      <Form
	    className={'direct-debit-form' + (isResidentialCustomer ? ' residential-form' : isBusinessCustomer ? ' business-form' : '')}
        noValidate
        onValidSubmit={this.submitDirectDebit}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        loading={this.state.processing}>

        {hasExtOrPlan && 
          <label className="error-header">You cannot create or modify your direct debit details when{' '}
          there is an active payment plan or extension on your account.</label>
        }

        {(bankDetailsSaved)
          ? <ActiveHeader />
          : <InactiveHeader />
        }

        <ValidatedInput
          required={!bankDetailsSaved}
          name="AccountHolderName"
          label={<label style={{ color: 'black' }}>Bank account name(s)</label>}
          fluid
          value={bankDetails && bankDetails.accountHolderName}
          disabled={bankDetailsSaved || !!hasExtOrPlan}
          validations={{
            isWords: true,
            maxLength: 255
          }}
          validationErrors={{
            maxLength: 'Please enter a maximum of 255 characters.',
            isWords: 'Please enter letters and spaces only.',
            isDefaultRequiredValue: 'A value is required for this field.'
          }}
        />
		<ValidatedInputWithAutocomplete
          required={!bankDetailsSaved}
          name="BankName"
          label={<label style={{ color: 'black' }}>Bank name(s)</label>}
		  placeholder="Begin typing to search"
          fluid
          value={bankDetails && bankDetails.bankName}
		  items={this.state.bankCodes}
          disabled={bankDetailsSaved || !!hasExtOrPlan || !this.state.bankCodes}
          validations={{
			maxLength: 32,
			matchesId: this.state.bankCodes
		  }}
          validationErrors={{
            maxLength: 'Please enter a maximum of 32 characters.',
            isDefaultRequiredValue: 'A value is required for this field.',
			matchesId: 'Please select a bank from the list.',
          }}
        />
        <ValidatedInput
          required={!bankDetailsSaved}
          name="BranchNumber"
          label={<label style={{ color: 'black' }}>BSB Number</label>}
          placeholder="_ _ _ _ _ _"
          fluid
          value={bankDetails && bankDetails.branchNumber}
          disabled={bankDetailsSaved || !!hasExtOrPlan}
          validations={bsbValidation}
          validationErrors={{
            maxLength: 'Please enter 6 numbers.',
            minLength: 'Please enter 6 numbers.',
            isNumeric: 'Please use numbers only.',
            isDefaultRequiredValue: 'A value is required for this field.'
          }}
        />
        <ValidatedInput
          required={!bankDetailsSaved}
          name="BankAccountNumber"
          label={<label style={{ color: 'black' }}>Bank Account Number</label>}
          fluid
          value={bankDetails && bankDetails.bankAccountNumber}
          disabled={bankDetailsSaved || !!hasExtOrPlan}
          validations={{
            isNumeric: true,
            minLength: 1,
            maxLength: 9
          }}
          validationErrors={{
            maxLength: 'Please use a maximum of 9 numbers.',
            minLength: 'Please use at least 1 number.',
            isNumeric: 'Please use numbers only.',
            isDefaultRequiredValue: 'A value is required for this field.'
          }}
        />

        {(!bankDetailsSaved && !hasExtOrPlan) &&
          <div className="checkboxWithLinks field required">
            <ValidatedCheckbox
              required
              hideErrorMessage={false}
              name="termsconditionscheckbox"
              style={{ color: 'white' }}
              checked={this.state.termsConditionsChecked}
              onChange={() => this.setState((prevState) => ({ termsConditionsChecked: !prevState.termsConditionsChecked }))}
            />
            <label className="link">
              I agree for the outstanding amount to be paid in full on the due date, and I consent to{' '}
              <ExternalLink href={Urls.TERMSANDCONDITIONS_DIRECTDEBIT}>Direct Debit T&C's</ExternalLink> and{' '}
              <ExternalLink href={Urls.DATA_COLLECTION_STATEMENT}>Privacy Statement</ExternalLink>
            </label>
          </div>
        }

        {(bankDetails && bankDetailsSaved)
          ? <UpdateButtons />
          : <SaveButton />
        }

        <DirectDebitStopModal
          openModal={() => this.openModal('directDebitStop')}
          closeModal={() => this.closeModal('directDebitStop')}
          confirmModal={this.directDebitStop}
          isOpen={this.isModalOpen('directDebitStop')}
          theme={theme}
        />

        <DirectDebitConfirmModal
          openModal={() => this.openModal('directDebitConfirmation')}
          closeModal={() => this.closeModal('directDebitConfirmation')}
          confirmModal={this.confirmDirectDebitModal}
          isOpen={this.isModalOpen('directDebitConfirmation')}
          theme={theme}
        />

        <DirectDebitSavedModal
          openModal={() => this.openModal('directDebitSaved')}
          closeModal={() => this.closeModal('directDebitSaved')}
          isOpen={this.isModalOpen('directDebitSaved')}
          theme={theme}
        />

        <UsingDirectDebitModal
          openModal={() => this.openModal('usingDirectDebit')}
          closeModal={() => this.closeModal('usingDirectDebit')}
          isOpen={this.isModalOpen('usingDirectDebit')}
          theme={theme}
        />

      </Form>
    );
  }

  renderAutoPayTab = () => {
    const { theme } = this.props.theme;
    const { autoPaymentDetails, autoPaymentDetailsSaved, restrictedNavigation, primaryAccount } = this.state;
    const { hasLoaded, customerPremise: premise, pageConfig } = this.props.customer;
    const isResidentialCustomer = pageConfig && pageConfig.productName === "residential";
    const isBusinessCustomer = pageConfig && pageConfig.productName === "business";

    const SaveButton = () => (
      <Form.Field disabled={this.state.disabled}>
        <Button
          style={{ backgroundColor: theme.important.backgroundColor }}
          className="state-button"
          loading={this.state.processing}
          disabled={!this.state.canSubmit || !this.state.termsConditionsChecked || !primaryAccount}
          secondary
          fluid>
          Save auto payment
        </Button>
      </Form.Field>
    )

    const UpdateButtons = () => (
      <React.Fragment>
        <Form.Field>
          <Button
            type='submit'
            style={{ backgroundColor: theme.important.backgroundColor }}
            secondary
            fluid
            onClick={() => this.setState({ autoPaymentDetailsSaved: false })}>
            Update auto payment
          </Button>
        </Form.Field>

        <Button
          onClick={() => this.openModal('autoTopupStop')}
          style={{
            color: '#0060ae',
            background: 'none',
            boxShadow: `0 0 0 1px #0060ae inset`
          }}
          fluid>
          Stop auto payment
        </Button>
      </React.Fragment>
    );

    const ActiveFields = () => (
      <React.Fragment>
        <div className="active-header">
          <Header as="h4">Auto payment is active</Header>
          <TickInCircleIcon color={ "#0060ae" } />
        </div>
        <div
          className='active-body'
          style={{ color: theme.primary.color, borderBottom: '1px solid lightGrey' }}
        >
          <div className="cardBlock-top">
            <p style={{ color: '#415879', fontSize: '13px' }}>
              Top up amount
            </p>
            <p style={{ fontSize: '40px', color: "#0060ae" }}>
              ${Math.abs(autoPaymentDetails.topUpAmount)}
            </p>
          </div>
          <div className="cardBlock-bottom">
            <p style={{ color: '#415879', fontSize: '13px' }}>
              Minimum balance
          </p>
            <p style={{ fontSize: '40px', color: "#0060ae" }}>
              ${Math.abs(autoPaymentDetails.threshold)}
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    const CreditCardReadOnly = () => (
      <List className="accounts-list">
        <List.Item>
          <List.Header
            style={{
              marginBottom: '0.75em',
              fontWeight: 'bold',
              backgroundColor: theme.tertiary.backgroundColor
            }}>
            <span style={{ minWidth: '10%', overflowWrap: 'break-word' }}>{primaryAccount.cardholderName}</span>
            <Label style={{
              marginLeft: '1em',
              color: 'white',
              backgroundColor: theme.tertiary.backgroundColor
            }}>Primary</Label>
          </List.Header>

          <Grid
            columns={2}
            verticalAlign="bottom"
            style={{ marginBottom: '0.25em' }}>
            <Grid.Column>
              <List.Header style={{ fontWeight: 'bold' }}>
                <List.Icon name={`cc ${primaryAccount.cardScheme.toLowerCase()}`} /> {primaryAccount.cardNumber}
              </List.Header>
            </Grid.Column>
          </Grid>
        </List.Item>

        {(!autoPaymentDetailsSaved) &&
          <Button
            onClick={() => this.openModal('autoTopupAddCard')}
            basic
            fluid>
            Use another card
          </Button>
        }
      </List>
    )

    return (
      <Form
	    className={'autopayment-form' + (isResidentialCustomer ? ' residential-form' : isBusinessCustomer ? ' business-form' : '')}
        noValidate
        onValidSubmit={this.submitAutoTopup}
        onValid={this.onFormValid}
        onInvalid={this.onFormInvalid}
        loading={this.state.processing}>

        {autoPaymentDetailsSaved
          ? <ActiveFields />
          :
          <React.Fragment>
            <InfoIcon height="16px" style={{ float: 'right', color: '#0060AE' }} onClick={() => this.openModal('usingAutoTopup')} />
            <Header as="h4">Auto payments is a convenient way to top up your account when a minimum balance is reached.</Header>
            <ValidatedInput
              required={!autoPaymentDetailsSaved}
              name="TopUpAmount"
              label={<label style={{ color: 'black' }}>Payment amount</label>}
              type="number"
              fluid
              value={autoPaymentDetails && autoPaymentDetails.topUpAmount}
              disabled={autoPaymentDetailsSaved}
              validations={{
                isNumeric: true,
                min: 50,
                max: 10000
              }}
              validationErrors={{
                max: 'Maximum payment amount of $10,000',
                min: 'Minimum payment amount of $50',
                isNumeric: 'Please use whole numbers only',
                isDefaultRequiredValue: 'A value is required for this field'
              }}
            />
            <ValidatedInput
              required={!autoPaymentDetailsSaved}
              name="Threshold"
              label={<label style={{ color: 'black' }}>Minimum account balance</label>}
              type="number"
              fluid
              value={autoPaymentDetails && autoPaymentDetails.threshold}
              disabled={autoPaymentDetailsSaved}
              validations={{
                isNumeric: true,
                min: 0,
                max: 10000,
                // topUpAmount must increase current account balance to match the value of this field
                minBalance: (values, value) => {
                  return premise.estimatedBalance + Number(values.TopUpAmount || 0) > Number(value)
                }
              }}
              validationErrors={{
                max: 'Maximum threshold amount of $10,000',
                min: 'Minimum threshold amount of $0',
                isNumeric: 'Please use numbers only.',
                isDefaultRequiredValue: 'A value is required for this field.',
                minBalance: `Auto payments cannot be setup until your account balance ($${premise.estimatedBalance}) is higher than the minimum balance set.`
              }}
            />
          </React.Fragment>
        }

        <Form.Field disabled={this.state.disabled} className={`${!autoPaymentDetailsSaved && 'required'}`}> 
          <label style={{ color: 'black' }}>Payment method</label>
          {primaryAccount
            ? <CreditCardReadOnly />
            : <Button
                type='button'
                onClick={() => this.openModal('autoTopupAddCard')}
                secondary
                fluid>
                Add a card
              </Button>
          }
        </Form.Field>

        {!autoPaymentDetailsSaved &&
          <div className="checkboxWithLinks field required">
            <Form.Input type="hidden" name="Trigger" value="THRESHOLD" />
            <ValidatedCheckbox
              required
              hideErrorMessage={false}
              name="termsconditionscheckbox"
              style={{ color: 'white' }}
              checked={this.state.termsConditionsChecked}
              onChange={() => this.setState((prevState) => ({ termsConditionsChecked: !prevState.termsConditionsChecked }))}
            />
            <label className="link">
              I agree for the auto payment amount to be paid from my primary card when the account balance is below the minimum I have set.
            </label>
          </div>
        }

        {(autoPaymentDetails && autoPaymentDetailsSaved)
          ? <UpdateButtons />
          : <SaveButton />
        }

        <AutoTopupStopModal
          openModal={() => this.openModal('autoTopupStop')}
          closeModal={() => this.closeModal('autoTopupStop')}
          confirmModal={this.autoTopupStop}
          isOpen={this.isModalOpen('autoTopupStop')}
          theme={theme}
        />

        <AutoTopupConfirmModal
          openModal={() => this.openModal('autoTopupConfirmation')}
          closeModal={() => this.closeModal('autoTopupConfirmation')}
          confirmModal={this.confirmAutoTopUpModal}
          isOpen={this.isModalOpen('autoTopupConfirmation')}
          {...this.state.autoPaymentDetails}
          theme={theme} />

        <AutoTopupSavedModal
          openModal={() => this.openModal('autoTopupSaved')}
          closeModal={() => this.closeModal('autoTopupSaved')}
          isOpen={this.isModalOpen('autoTopupSaved')}
          theme={theme} />

        <UsingAutoTopupModal
          openModal={() => this.openModal('usingAutoTopup')}
          closeModal={() => this.closeModal('usingAutoTopup')}
          isOpen={this.isModalOpen('usingAutoTopup')}
          theme={theme}
        />

        <AutoTopupAddCardModal
          openModal={() => this.openModal('autoTopupAddCard')}
          closeModal={() => this.closeModal('autoTopupAddCard')}
          confirmModal={() => this.props.router.navigate('/accountSettings/payments')}
          isOpen={this.isModalOpen('autoTopupAddCard')}
          theme={theme} />

      </Form>
    );
  }

  generateModals = (modalList) => (
    <React.Fragment>
      {modalList.map(key => {
        let modal = this.state[key]
        const { theme } = this.props.theme;
        if (!modal) return null;

        return (
          <Modal
            style={{ textAlign: 'center', background: theme.background, color: theme.color }}
            name={modal.name || modal.key}
            key={key}
            size="mini"
            open={this.isModalOpen(key)}
            centered>
            <Modal.Header>{modal.header}</Modal.Header>
            <Dimmer inverted active={modal.render === false || modal.loading}>
              <Loader />
            </Dimmer>
            <Modal.Content>

              {modal.render !== false && modal.content({
                ...this.state
              }, {
                  ...this.state[key]
                }, this.props)}
            </Modal.Content>
            <Modal.Actions>
              {modal.render !== false && modal.actions({
                ...this.state
              }, {
                  ...this.state[key]
                }, this.props)}
            </Modal.Actions>
          </Modal>
        )
      })}
    </React.Fragment>
  )
  
  prepay = () => {
    const {
      restrictedNavigation,
      bankDetailsSaved,
      autoPaymentDetailsSaved,
      activePayment,
      isLoading,
      loadingPrimaryAccount } = this.state;
    const { customer } = this.props.customer;
    const { theme } = this.props.theme;

    const hasAutoPayment = autoPaymentDetailsSaved || (activePayment && activePayment.isAutoPaymentActive);
    const hasDirectDebit = bankDetailsSaved || (activePayment && activePayment.isDirectDebitActive);
    
    const modals = [
      'mPaymentConfirm',
      'mPaymentSuccess',
      'mPaymentPending',
      'mPaymentFailure',
      'mPaymentSuccessCardSaveFailure',
      'ErrorModal'
    ]

    if (customer.isFromCCB) {
      // the old payment page
      return (
        <Layout
          pageTitle="Make payment"
          accountPicker={restrictedNavigation ? false : undefined}
          layout={ColumnLayout}>

          {this.generateModals(modals)}

          <div className="no-tabs-form">
            {this.renderPayNowForm()}
          </div>
        </Layout>
      );
    }

    const prepayPanels = [
      {
        menuItem: 'Top up',
        render: () => <Tab.Pane loading={isLoading}>{this.renderPayNowForm()}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='autopay'>
            <span>Auto payments</span>
            {hasAutoPayment && <TickInCircleIcon height={20} />}
          </Menu.Item>
        ),
        render: () => <Tab.Pane loading={loadingPrimaryAccount || isLoading}>{this.renderAutoPayTab()}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='directdebit'>
            <span>Direct debit</span>
            {hasDirectDebit && <TickInCircleIcon height={20} />}
          </Menu.Item>
        ),
        render: () => <Tab.Pane loading={isLoading}>{this.renderDirectDebit()}</Tab.Pane>
      }
    ];

    // new payment page
    return (
      <Layout
        pageTitle="Make payment"
        accountPicker={restrictedNavigation ? false : undefined}
        layout={ColumnLayout}>

        {this.generateModals(modals)}

        <Tab className={'horizontal-panel-menu residential'} onTabChange={this.onTabChange} panes={prepayPanels} />
      </Layout>
    );
  }

  onTabChange = (ev, data) => {
    const { primaryAccount, autoPaymentDetails, bankDetails } = this.state;

    // when clicking on tabs, reload data
    this.fetchActivePayment();

	var isBusiness = data.className.indexOf('business') >= 0;
	var isAutoPaymentTab = isBusiness ? false : (data.activeIndex === 1);
	var isDirectDebitTab = data.activeIndex === (isBusiness ? 1 : 2)

    if (isAutoPaymentTab) {
      this.setState({
        loadingPrimaryAccount: true,
        isLoading: true
      });

      this.fetchPaymentAccount()
        .then(() => this.setState({ loadingPrimaryAccount: false }));

      this.fetchAutoPaymentDetails()
        .then(() => this.setState({ isLoading: false }));
    }
    else if (isDirectDebitTab) {
      this.setState({ isLoading: true});
      this.fetchBankDetails()
        .then(() => this.setState({ isLoading: false }));
    }
  }

  BillSummary = () => {
    const { billingDetails: billing, paymentExtension, paymentPlan } = this.state;
    const { customerPremise, pageConfig } = this.props.customer;
    const { theme } = this.props.theme;
    const isBusinessCustomer = pageConfig && pageConfig.productName === "business";

    let numberUnpaidBills = billing && billing.outStanding.length;
    let numberOverdueBills = billing && billing.overdue.length;

    // extension is same amount, just with a different due date
    let totalAmount = billing ? billing.totalAmountDue : 0;

    if (paymentPlan) {
      const current = paymentPlan.currentInstalment;
      const status = paymentPlan.instalmentStatus(current);
      totalAmount = 0;

      // if current instalment is overdue, check to see if the next instalment is coming due as well
      // and add that sum to the current amount
      let overdueAmount = paymentPlan.overdueAmount;
      if (status === PaymentPlanStatus.OVERDUE) {
        const nextInstalment = paymentPlan.upcomingInstalments[0];
        
        // we only add the next instalment amount if it's coming due (due within 3 days of today)
        if (nextInstalment && paymentPlan.instalmentStatus(nextInstalment) === PaymentPlanStatus.DUE) {
          overdueAmount += nextInstalment.AmountOutstanding;
        }
      }

      if (status === PaymentPlanStatus.OVERDUE) {
        totalAmount = overdueAmount;
      }
      else if (status === PaymentPlanStatus.DUE) {
        totalAmount = current.AmountOutstanding;
      }
    }

    if (!billing || totalAmount === 0) {
      //if (customerPremise.hasPrePayBilling) return null;
      
      return (
        <div className='post-pay-header'>
          <Header size="medium">Nice work! You have no payments due!</Header>
          <Header size="small">Prefer to stay ahead? You can make a{' '}
            payment by card at any time through the aurora+ app.</Header>
        </div>
      );
    }

    // only show 2 decimal places
    totalAmount = totalAmount.toFixed(2);

    // loop over all outstanding (unpaid) bills and display summary details for payment
    const BillDetailsList = () => (
      <div className="bills-list">
        {BillingDetails.sortByDate(billing.outStanding).map((value, index) => {

          if (!value.billPeriodDateStart || !value.billPeriodDateEnd) {
            return null;
          }

          return (
            <div key={index} className='bill-details'>
              <div className='row'>
                <span>Period</span>
                <span>{moment(value.billPeriodDateStart).format("D MMM")} to {moment(value.billPeriodDateEnd).format("D MMM")}</span>
              </div>
              <div className='row'>
                <span>Bill No.</span>
                {/* DPS-138, Bills that have the prefix of CCB in their doc number don't have valid PDFs, so hide the button*/}
                <span>{value.billId} {(!value.invoiceDocNumber.includes('CCB')) && <a className='pdf-link' onClick={() => this.billClicked(value.billId)}>View PDF</a>}</span>
              </div>
            </div>
          );
        })}
      </div>
    )

    const SelectAmountButton = () => (
      <React.Fragment>
        <InfoIcon height="16px" style={{ float: 'right' }} onClick={() => this.openModal('usingTopup')} />
        <label>Total amount due</label>
        <Button
          fluid
          type="button"
          color='grey'
          onClick={ev => {
            ev.preventDefault();
            let newState = { selectedTopUpOption: null, amount: '' };

            if (this.state.selectedTopUpOption !== totalAmount) {
              newState = {
                selectedTopUpOption: totalAmount,
                amount: totalAmount
              }
            }

            this.setState(newState);
          }}
          value={totalAmount}
          active={this.state.selectedTopUpOption === totalAmount}>
          ${totalAmount}
        </Button>
      </React.Fragment>
    )
    
    // AUIX-149 Adjusts number formatting adding a comma to over 1000
    const BusinessHeader = () => {
      const formattedTotalAmount = Number(totalAmount).toLocaleString(undefined, { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      });
    
      return (
        <div className='business-only-header'>
          <p className='label'>Bill details</p>
          <Header size='huge'>
            ${formattedTotalAmount}
          </Header>
        </div>
      );
    };

    return (
      <React.Fragment>
        <Form.Field disabled={this.state.disabled} className="topup-options">
          {isBusinessCustomer
            ? <BusinessHeader />
            : <SelectAmountButton />
            }
        </Form.Field>

        {!paymentPlan && (

          (numberOverdueBills > 1 || numberUnpaidBills > 2) ? (
              <div className='topup-link'>
                <Link to="/transactionhistory/transactions">View Transactions and Bills</Link>
              </div>
            )
            : <BillDetailsList />
        )}

        <UsingTopupModal
          openModal={() => this.openModal('usingTopup')}
          closeModal={() => this.closeModal('usingTopup')}
          isOpen={this.isModalOpen('usingTopup')}
          theme={theme}
        />
      </React.Fragment>


    );
  }
  
  renderBillPaymentForm = () => {
    const { BillSummary } = this;
    const { restrictedNavigation, isReferenceModalOpen } = this.state;
    const { theme } = this.props.theme;
    const { hasLoaded, customerPremise, pageConfig } = this.props.customer;
    const isResidentialCustomer = pageConfig && pageConfig.productName === "residential";
    const isBusinessCustomer = pageConfig && pageConfig.productName === "business";
    
    const accountNumberWithCheckDigit = `${this.state.accountNumber || ''}${this.state.accountNumberCheckDigit || ''}`;
    
    return (
      <div className="payment-form">
        
        <Form
	      className={'topup-form' + (isResidentialCustomer ? ' residential-form' : isBusinessCustomer ? ' business-form' : '')}
          noValidate
          onValidSubmit={this.submit}
          onValid={this.onFormValid}
          onInvalid={this.onFormInvalid}
          loading={this.state.processing || this.state.isLoading}>

          <BillSummary />

          <Form.Field className={`${isResidentialCustomer && 'required'}`}>
            <label>Payment amount</label>
            <ValidatedInput
              required
              validations={{
                'isNumeric': true,
                'min': this.props.minAmount,
                'matchRegexp': /^\d*(\.\d{0,2})?$/
              }}
              validationErrors={{
                matchRegexp: 'Please enter no more than 2 decimal places',
                min: `Minimum payment amount of $${this.props.minAmount}`,
                isDefaultRequiredValue: `Minimum payment amount of $${this.props.minAmount}`
              }}
              name="amount"
              className="payment-other-value-input"
              type='number'
              icon="dollar"
              step="0.01"
              min={this.props.minAmount}
              fluid
              iconPosition="left"
              onKeyPress={e => ['+', '-', 'E', 'e'].indexOf(e.key) > -1 && e.preventDefault()}
              value={this.state.amount || (customerPremise.billTotalAmount > 0 ? customerPremise.billTotalAmount : null)}
              disabled={!!this.state.selectedTopUpOption || this.state.disabled || customerPremise.billTotalAmount > 0} />
          </Form.Field>

          {!restrictedNavigation && (
            <p>
              To see BPay details,{' '}
              <a href="#" onClick={this.openReferenceModal}>
                click here
              </a>
            </p>
          )}

          <Modal
            size='tiny'
            open={isReferenceModalOpen}
            onClose={this.closeReferenceModal}
            style={{ color: theme.modal.color, backgroundColor: theme.modal.backgroundColor }}
          >
            <Modal.Header>BPay Details</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>
                
                </Header>
                Biller Code: 5595 <br />
                Reference Number: { accountNumberWithCheckDigit }
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                fluid
                onClick={this.closeReferenceModal}
              >
                Close
              </Button>
            </Modal.Actions>
          </Modal>

          <Form.Field disabled={this.state.disabled} className={`${isResidentialCustomer && 'required'}`}>
          <label className="custom-label">Payment method</label>
            <PaymentMethod
              ref={cmp => this.paymentMethod = cmp}
              restrictedNavigation={restrictedNavigation}
              customer={this.props.customer}
              config={pageConfig}
              onSelect={account => { this.setState({ selectedAccount: account }) }}
              onError={x => this.setState({ error: true })}
              disabled={this.state.disabled} />
          </Form.Field>

          <Form.Field disabled={this.state.disabled}>
            <Button
              style={{ backgroundColor: theme.important.backgroundColor }}
              className="state-button"
              loading={this.state.processing}
              disabled={!this.state.canSubmit || this.state.paymentSuccessful}
              secondary
              fluid>
              {this.state.payNowButtonText}
            </Button>
          </Form.Field>
        </Form>

      </div>
    );
  }

  postPay = (props) => {
    const {
      restrictedNavigation,
      bankDetailsSaved,
      autoPaymentDetailsSaved,
      activePayment,
      isLoading,
      loadingPrimaryAccount } = this.state;
    const { hasLoaded, customerPremise, customer, pageConfig } = this.props.customer;
    const isBusinessCustomer = pageConfig && pageConfig.productName === "business";

    if (!hasLoaded || !customerPremise)
      return null;

    const hasAutoPayment = autoPaymentDetailsSaved || (activePayment && activePayment.isAutoPaymentActive);
    const hasDirectDebit = bankDetailsSaved || (activePayment && activePayment.isDirectDebitActive);

    let modals = [
      'mPaymentConfirmPostPay',
      'mPaymentSuccessPostPay',
      'mPaymentConfirm',
      'mPaymentSuccess',
      'mPaymentPending',
      'mPaymentFailure',
      'mPaymentSuccessCardSaveFailure',
      'ErrorModal'
    ]

    const postpayPanels = [
      {
        menuItem: 'Top up',
        render: () => <Tab.Pane loading={isLoading}>{this.renderBillPaymentForm()}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='autopay'>
            <span>Auto payments</span>
            {hasAutoPayment && <TickInCircleIcon height={20} />}
          </Menu.Item>
        ),
        render: () => <Tab.Pane loading={loadingPrimaryAccount || isLoading}>{this.renderAutoPayTab()}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='directdebit'>
            <span>Direct debit</span>
            {hasDirectDebit && <TickInCircleIcon height={20} />}
          </Menu.Item>
        ),
        render: () => <Tab.Pane loading={isLoading}>{this.renderDirectDebit()}</Tab.Pane>
      }
    ];

    const businessPanels = [
      {
        menuItem: 'Top up',
        render: () => <Tab.Pane loading={isLoading}>{this.renderBillPaymentForm()}</Tab.Pane>
      },
      {
        menuItem: (
		  <Menu.Item key='directdebit'>
            <span>Direct debit</span>
            {hasDirectDebit && <TickInCircleIcon height={20} />}
          </Menu.Item>
		),
        render: () => <Tab.Pane loading={isLoading}>{this.renderDirectDebit()}</Tab.Pane>
      }
    ];

    // business-only payment page (no tabs)
    if (isBusinessCustomer) {
      return (
        <Layout pageTitle="Make payment" layout={ColumnLayout}>
          {this.generateModals(modals)}

		  <Tab className={'horizontal-panel-menu business'} onTabChange={this.onTabChange} panes={businessPanels} />
        </Layout>
      );
    }

    // new residential payment page (with tabs)
    return (
      <Layout pageTitle="Make payment" layout={ColumnLayout}>
        {this.generateModals(modals)}

        <Tab className={'horizontal-panel-menu residential'} onTabChange={this.onTabChange} panes={postpayPanels} />
      </Layout>
    );
  }

  billClicked = billId => {
    const { customerPremise } = this.props.customer;
    const { isApp } = this.props.app;


    openBill(billId, customerPremise.parentAccountID, isApp, null, customerPremise.serviceAgreementStatus ).catch(() => {
      this.openModal('Oops!', `We can't retrieve your bill right now. Please try again later or contact the Aurora Energy Team by clicking on Contact Us in your App menu.`);
    });
  }

  openReferenceModal = (e) => {
    e.preventDefault(); // Prevent navigation
    this.setState({ isReferenceModalOpen: true });
  }

  closeReferenceModal = () => {
    this.setState({ isReferenceModalOpen: false });
  }

  render() {
    const { customerPremise } = this.props.customer;

    if (!customerPremise)
      return null;

    // Original logic commented out to force postpaid mode
    // return customerPremise.hasPrePayBilling
    //   ? this.prepay()
    //   : this.postPay();

    // This forces the component to always render in postpaid mode
    // This was a late requirement in the UI/UX project, done like this for safety see ticket AUIX-147: 
return this.postPay();
  }
}

export default withRouter(withApp(withCustomer(withTheme(Payment))));
