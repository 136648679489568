import ExternalLink from '../../../components/ExternalLink';

const termsText = `
I am the customer responsible for the payment of electricity accounts.

The above address is my principal residence.

I authorise:

Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) to use Centrelink Confirmation eServices to perform a Centrelink/DVA enquiry of my Centrelink or Department of Veterans' Affairs Customer details and concession card status in order to enable Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) to determine if I qualify, or continue to qualify for the Annual Electricity Concession.

The Australian Government Department of Human Services (the department) to provide the results of that enquiry to Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office).

The Australian Department of Immigration and Border Security and the Tasmanian Department of Premier and Cabinet to provide Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) with any personal information relating to me that is relevant in determining if I qualify, or continue to qualify for the Annual Electricity Concession.

I understand that:

The Australian Government Department of Human Services, the Australian Department of Immigration and Border Security and the Tasmanian Department of Premier and Cabinet will use information I have provided to Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) to confirm my eligibility for Annual Electricity Concession and will disclose to Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) personal information including my name, address, payment and concession card type and status.

This consent, once signed, remains valid while I am a customer of Aurora Energy unless I withdraw it by contacting Aurora Energy Pty Ltd or the applicable department.

I can obtain proof of my circumstances/details from the applicable department and provide it to Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office) so that my eligibility for the Annual Electricity Concession can be determined.

If I withdraw my consent or do not alternatively provide proof of my circumstances/details, I may not be eligible for the Annual Electricity Concession provided by Aurora Energy Pty Ltd and the Tasmanian Department of Treasury and Finance (State Revenue Office).

Aurora Energy and the State Revenue Office are collecting this personal information for the sole purpose of assessing your eligibility for an electricity concession. The information collected on this form is deemed personal information for the purposes of the Personal Information Protection Act 2004. Without this information, your application for the concession will not be able to proceed. You are able to request access to the personal information held about you, and to request it be corrected if necessary, by contacting Aurora Energy.

By submitting this online form you are agreeing to the fees that apply and acknowledge and understand the way Aurora Energy collects, uses and discloses your personal information as set out in our privacy collection statement.`;

export const termsAndConditions = {
  text: termsText,
  privacyLink: (
    <ExternalLink 
      href="https://www.auroraenergy.com.au/about/who-we-are/about-us-charters-policies"
      style={{ 
        color: '#2B5AAD',  // Aurora Energy blue
        textDecoration: 'underline'
      }}
    >
      privacy collection statement
    </ExternalLink>
  )
}; 