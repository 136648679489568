import React from "react";
import { Header, Accordion, Grid, Menu } from "semantic-ui-react";

import Responsive from "../components/Responsive/Responsive";
import Layout from "../layouts/Layout";
import FullWidthLayout from "../layouts/FullWidthLayout";
import ExternalLink from "./../components/ExternalLink";
import { withRouter } from '../utils/useRouter';

import { withCustomer } from "../contexts/Customer";
import { withTheme } from "../contexts/Theme";
import Analytics from "./../utils/Analytics";
import { a } from "react-spring";

const FAQ_LINK = "https://www.auroraenergy.com.au/residential/faqs";
const MORE_HELP_LINK = "https://www.auroraenergy.com.au/aurora-plus/learning-hub";
const AP_TAC_LINK = "https://www.auroraenergy.com.au/aurora-plus/aurora-plus-terms-and-conditions";
const PRIVACY_POLICY_LINK = "https://www.auroraenergy.com.au/about-us-charters-policies";
const CONCESSIONS_LINK = "https://www.auroraenergy.com.au/help/aurora-plus";

const pages = [
  {
    key: "app-support",
    title: "App Support",
    panels: [
      
      {
        key: "topic-2",
        title: "Will there be app updates?",
        content:
          "Yes. From time to time we will make updates to the app to ensure that you are ge" +
          "tting the best experience possible."
      },
      {
        key: "topic-3",
        title: "Will the app use much data?",
        content:
          "Data usage for the app will be minimal. You will be able to check the data usage" +
          " on the app via your mobile settings."
      },
      {
        key: "topic-4",
        title: "Can I turn off notifications?",
        content: (
          <Accordion.Content>
            <p>
              You can turn off in-app notifications via your phone settings
              however we recommend keeping them on as we will only be sending
              you important account notifications such as reminders when your
              account balance is getting low.*
            </p>
            <p>*Applies to Residential and Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-5",
        title: "Can I have multiple log ins?",
        content:
          "We are serious about your privacy and to ensure your private information is prot" +
          "ected, there will only be one username assigned to an account"
      },
      {
        key: "topic-6",
        title: "Can I login to the app from multiple or different devices?",
        content:
          "Yes. You can log into the app from multiple devices using the same username and " +
          "password."
      },
      {
        key: "topic-7",
        title: "How often will my usage data update? ",
        content:
          "Your usage data for the previous day will normally be updated by 12 pm the following day if Aurora Energy successfully receives the usage data from your smart meter. In some cases, there may be delays in receiving and processing your meter data - e.g. faulty meter, communications issues near your location, etc. Your usage graph will be blank until the meter data is sent to us"
      },
      {
        key: "topic-8",
        title: "aurora+ Terms & Conditions ",
        content: (
          <Accordion.Content>
           <p>
             <ExternalLink style={{ color: "#6EA7FF" }} href={AP_TAC_LINK}>Go here</ExternalLink> to view the aurora+ Terms & Conditions.&nbsp;
            </p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-9",
        title: "Privacy Policy",
        content: (
          <Accordion.Content>
            <p>
             <ExternalLink style={{ color: "#6EA7FF" }} href={PRIVACY_POLICY_LINK}>Go here</ExternalLink> to view the Aurora Energy Privacy Policy.&nbsp;
            </p>
          </Accordion.Content>
        )
      }
    ]
  },
  {
    key: "frequently-asked-questions",
    title: "Frequently Asked Questions",
    panels: [
      {
        key: "topic-1",
        title: "Why isn't my payment showing in my account balance?",
        content:
          "When you make a payment using your debit or credit card in the app or using the " +
          "aurora+ website, your account balance will update immediately. However, pay" +
          "ments made in other ways (e.g. BPAY or through the post office)  may take betwee" +
          "n 1-5 business days to process before your account balance is updated."
      },
      {
        key: "topic-2",
        title:
          "I don't have a credit card but want to make a payment, how can I do this?",
        content:
          "You can pay via debit card, BPAY, direct debit, over the phone, at Australia Pos" +
          "t, Service Tasmania and via cheque in the post."
      },
      {
        key: "topic-3",
        title: "I have a concession, will my discount apply?",
        content: (
          <Accordion.Content>
            <p>
              If you have an eligible concession card and you are the account
              holder, you may be eligible to receive the Tasmanian Government
              Annual Electricity Concession. Please contact the Aurora Customer
              Service team if you are unsure if you qualify. To view information
              on the current concession discount,
              please <ExternalLink style={{ color: "#6EA7FF" }} href={CONCESSIONS_LINK}>go here.</ExternalLink>
            </p>
            <p>
              *Note: pensioner discount concessions only apply to Residential
              and Solar accounts.
            </p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-4",
        title: "What happens if I don't keep up with my payments?",
        content: (
          <Accordion.Content>
            <p>
              If you have an outstanding amount on your statement, you will have
              14 days to pay the balance; failure to pay by the due date may
              result in late payment fees and overdue interest, as well as
              further credit action such as disconnection. We understand that
              circumstances can change and this may affect how you pay; we can
              help – just call our Aurora Customer Service Team.
            </p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-6",
        title: "Why is there weather information on the app?",
        content: (
          <Accordion.Content>
            <p>
              The weather feature allows you to see how the weather can impact
              your power use. For example, on cold days you might find your
              power usage goes up because you're using more heating; or if you
              have solar, you might see your export power reduce on cloudy days.
              Having these sorts of insights can help you understand more about
              your usage patterns.*
            </p>
            <p>*Applies to Residential and Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-7",
        title: "Why is there weather information but no usage information?",
        content: (
          <Accordion.Content>
            <p>
              The weather feature shows the forecasted weather in your area for
              up to the next 3 days. When you can see the weather but no usage
              information underneath, it's because the forecast is for today or
              a future day, and your usage hasn't happened yet. We'll update
              your usage once we get it. *
            </p>
            <p>*Applies to Residential and Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-8",
        title: "What is Solar Export?",
        content: (
          <Accordion.Content>
            <p>
              When your solar panels are generating power, that power goes
              towards your household usage. If you're generating more than
              you're using at any one time, the excess power is sent, or
              exported, to the electricity grid and is known as 'solar export'.
              You'll receive a rebate for your solar export called the
              'Residential Standard Feed-in-Tariff' on your statement.*
            </p>
            <p>*Applies to Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-10",
        title: "Why can't I see any solar export?",
        content: (
          <Accordion.Content>
            <p>
              The most likely reason for not seeing any solar export, is you're
              using your solar power in your house, and there is no excess power
              exporting to the grid. Less likely, is that your inverter is
              turned off; or your solar system is not working as it should. If
              you think your solar system isn't working, you'll need to contact
              your solar installer.*
            </p>
            <p>*Applies to Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-11",
        title:
          "Why can't I see any usage for a certain time period on the app?",
        content: (
          <Accordion.Content>
            <p>
              You might not have used any power in that time; or, you're
              generating enough solar power to cover your usage and didn't need
              to buy any power from us.*
            </p>
            <p>*Applies to Solar accounts only.</p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-13",
        title: "aurora+ insights questions",
        dependsOn: "auroraInsights",
        content: (
          <Accordion.Content>
            <p>
              For more information&nbsp;
              <ExternalLink style={{ color: "#6EA7FF" }} href={MORE_HELP_LINK}>go here.</ExternalLink>
            </p>
          </Accordion.Content>
        )
      },
      {
        key: "topic-14",
        title: "Other questions",
        content: (
          <Accordion.Content>
            <p>
              For more information&nbsp;
              <ExternalLink style={{ color: "#6EA7FF" }} href={MORE_HELP_LINK}>go here.</ExternalLink>
            </p>
          </Accordion.Content>
        )
      }
    ]
  }
];

export class Help extends React.Component {
  state = {
    activePage: -1,
    activeItem: -1
  };

  updatePageIndex = (level1, level2) => {
    if (level1) {
      let idx = pages.findIndex(p => p.key === level1);
      this.setState({ activePage: idx });

      if (level2) {
        let page = pages.find(p => p.key === level1);
        let idx = page.panels.findIndex(p => p.key === level2);
        if (idx > -1) {
          this.setState({ activeItem: idx });
        }
      }
    }
  };

  componentDidMount() {
    let { level1, level2 } = this.props.router.params;

    this.updatePageIndex(level1, level2);
  }

  render() {
    const { theme } = this.props.theme;
    const { pageConfig } = this.props.customer;

    return (
      <Layout
        className="help"
        layout={FullWidthLayout}
        accountPicker={false}
        header={
          <Responsive
            as={Header}
            greaterThan="mobile"
            size="large"
            textAlign="center"
            >
          </Responsive>
        }
        requiresCustomer={false}
        pageTitle="Help"
      >
        <Grid centered stackable className="help">
          <Grid.Column computer={4} tablet={5}>
            <Menu fluid vertical>
              {pages.map((page, i) => (
                <Menu.Item
                  link
                  key={page.key}
                  active={this.state.activePage === i}
                  style={{
                    color: this.state.activePage === i ? theme.primary.color : "",
					background: theme.card.default.background,
                  }}
                  className={`tab-button ${
                    this.state.activePage === i ? "tab-button-active" : ""
                  }`}
                  onClick={() => {
                    this.setState({ activePage: i, activeItem: -1 });
                    Analytics.pageview(`/help/${page.key}`);
                  }}
                >
                  {page.title}
                </Menu.Item>
              ))}
            </Menu>
          </Grid.Column>
          <Grid.Column computer={11} tablet={10}>
            {pages.map((page, i) => {
              // filter panels base on page config
              const panels = page.panels.filter(panel =>
                panel.hasOwnProperty("dependsOn") &&
                pageConfig &&
                pageConfig.functionality.hasOwnProperty(panel.dependsOn) &&
                !pageConfig.functionality[panel.dependsOn]
                  ? false
                  : true
              );

              return this.state.activePage === i ? (
                <Accordion
                  styled
                  fluid
                  panels={panels}
                  key={page.key}
                  defaultActiveIndex={this.state.activeItem}
				  style={{
					...theme.card.default,
				  }}
                  onTitleClick={(ev, data) => {
                    Analytics.pageview(
                      "/help/" +
                        page.key +
                        "/" +
                        data.content
                          .toLowerCase()
                          .replace(/ /gm, "-")
                          .replace("?", "")
                    );
                    
                    // Log GTM event data
                    //console.log('GTM Event:', {
                    //  category: 'Help',
                    //  action: 'View Help Article',
                    //  label: `FAQ_${panels[data.index].title}`
                    //});
                    
                    Analytics.event({
                      category: 'Help',
                      action: 'View Help Article',
                      label: `FAQ_${panels[data.index].title}`
                    });
                  }}
                />
              ) : null;
            })}
          </Grid.Column>
        </Grid>
      </Layout>
    );
  }
}

export default withRouter(withCustomer(withTheme(Help)));
