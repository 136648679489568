import axios from 'axios';
import { Button, Card, Grid, Loader, Modal, Segment } from "semantic-ui-react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PowerHoursLayout from "../../layouts/PowerHoursLayout"
import Layout from "../../layouts/Layout"
import { NavLink, useNavigate } from "react-router-dom";
import { withCustomer } from "../../contexts/Customer";
import { useMemo, useState } from "react";
import { Form } from "formsy-semantic-ui-react";
import { FORM_STATE } from "../../utils/FormState";
import { useEffect } from "react";
import { PowerHoursEvent, sortPowerHoursEvents } from '../../model/PowerHours';
import Close2Icon from "../../components/Icons/Close2Icon";
import PowerHoursIcon from "../../components/Icons/PowerHoursIcon";
import PowerHoursCard, { PowerHoursSummary } from '../../components/PowerHoursCard/PowerHoursCard';
import './PowerHours.css';
import ValidatedCheckbox from '../../components/Forms/ValidatedCheckbox';
import ExternalLink from '../../components/ExternalLink';
import Analytics from '../../utils/Analytics';
import PowerHoursBannerLogo from '../../components/Icons/PowerHoursBannerLogo';
import moment from 'moment-timezone';
import { useTheme } from '../../contexts/Theme'

export default withCustomer(({ customer }) => {

	const navigate = useNavigate();

	const TERMS_CONDITIONS_LINK = "https://www.auroraenergy.com.au/aurora-plus/power-hours/aurora-plus-power-hours-terms-and-conditions";
	const FIND_OUT_MORE_LINK = "https://www.auroraenergy.com.au/aurora-plus/power-hours";

	const [loadedPowerHoursData, setLoadedPowerHoursData] = useState(false);
	const [powerHoursEvents, setPowerHoursEvents] = useState(null);
	const [canSubmitConfirmation, setCanSubmitConfirmation] = useState(false);
	const [confirmationSubmitting, setConfirmationSubmitting] = useState(false);
	const [formState, setFormState] = useState(FORM_STATE.DEFAULT);
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [confirmationChangeModalOpen, setConfirmationChangeModalOpen] = useState(false);
	const [confirmingPayload, setConfirmingPayload] = useState(null);
	const [confirmingChangePayload, setConfirmingChangePayload] = useState(null);
	const [submitFailMessage, setSubmitFailMessage] = useState(null);

	const { pageConfig } = customer;
	const isResidential = pageConfig.productName ? (pageConfig.productName == "residential") : undefined;
	
	const { theme } = useTheme();
	
	useEffect(() => {
		if (isResidential === false) {
			navigate("/");
		}
	}, [isResidential]);

	useEffect(() => {
		fetchPowerHoursData();

		Analytics.event({
			category: "Power Hours",
			action: "View Power Hours Page",
		});				

	}, []);

	const totals = useMemo(() => {
		if (powerHoursEvents && loadedPowerHoursData) {
			let totals = {
				cost: 0,
				usage: 0,
				hours: 0,
			}

			powerHoursEvents.forEach(event => {
				if (event.isValid && event.acceptedTimeSlot && event.calculatedDateTime) {
					totals.cost += event.cost;
					totals.usage += event.usage;					
					var hours = moment.duration(event.acceptedTimeSlot.endDateTime.diff(event.acceptedTimeSlot.startDateTime)).asHours();
					// Some early events have ended at the 59 minute mark, so some rounding needs to happen
					// Admin portal allows start/end of events on the half hour, so round to that
					hours = Math.round(hours * 2) / 2;
					totals.hours += hours;
				}
			});

			return totals;
		} else {
			return null;
		}
	}, [powerHoursEvents, loadedPowerHoursData]);

	function handleCloseModal() {
		setConfirmationModalOpen(false);
		setConfirmationChangeModalOpen(false);
		setSubmitFailMessage(null);
	}

	const animationTimer = 750;
	
	const validPremises = useMemo(() => {
		return customer.premises.filter(premise => {
			if (premise.serviceAgreementType !== "residential") return false;
			if (premise.IsFromCCB) return false;
			return true;
		});
	}, [customer]);
 
	function fetchPowerHoursData() {
		setLoadedPowerHoursData(false);
 
		const premisesRequest = axios.get(process.env.REACT_APP_API_BASE_URI + "customers/premises");
 
		const powerHoursRequest = axios.get(process.env.REACT_APP_API_BASE_URI + `powerhour/all`);
 
		return Promise.all([premisesRequest, powerHoursRequest])
			.then(([premisesResponse, powerHoursResponse]) => { 
 
				const premisesData = premisesResponse.data;
				const powerHoursData = powerHoursResponse.data.map(obj => new PowerHoursEvent(obj)); 
	
				// Link Power Hours events to premises using NMI
				const linkedPowerHoursData = linkPowerHoursToPremises(premisesData, powerHoursData);
 
				let events = sortPowerHoursEvents(linkedPowerHoursData);
 
				setPowerHoursEvents(events);
				setLoadedPowerHoursData(true);
			})
			.catch(err => {
				setPowerHoursEvents(null);
				setLoadedPowerHoursData(true);
			});
	}
 
	const linkPowerHoursToPremises = (premisesData, powerHoursData) => {
		const nmiToPremisesMap = new Map();
	
		premisesData.Premises.forEach(premise => {
			premise.Meters.forEach(meter => {
				if (meter.NMI) {
					nmiToPremisesMap.set(meter.NMI, premise);
				}
			});
		});
	
		const linkedPowerHours = powerHoursData.map(event => { 
			if (event.Customer && event.Customer.Nmi) { 
				const linkedPremise = nmiToPremisesMap.get(event.Customer.Nmi);
				if (linkedPremise) {
					return {
						...event,
						LinkedPremise: linkedPremise
					};
				}
			}
			return event;
		});
	
		return linkedPowerHours;
	};

	function showConfirmationModal(payload) {
		if (confirmationModalOpen) return;
		setConfirmingPayload(payload);
		setConfirmationModalOpen(true);
	}

	function showConfirmationChangeModal(payload) {
		if (confirmationChangeModalOpen) return;
		setConfirmingChangePayload(payload);
		setConfirmationChangeModalOpen(true);
	}

	function onConfirmValid() {
		setCanSubmitConfirmation(true);
	}

	function onConfirmInvalid() {
		setCanSubmitConfirmation(false);
	}

	function onConfirmSubmit(payload) {
		const endpoint = process.env.REACT_APP_API_BASE_URI + 'PowerHour/accept';

		Analytics.event({
			category: "Power Hours",
			action: "Lock in time Slot",
			value: payload.AccountId + "|" + payload.PowerHourTimeSlotId,
		});																						
		
		setConfirmationSubmitting(true);
		setFormState(FORM_STATE.LOADING);
		axios
			.post(endpoint, payload)
			.then(() => {
				setFormState(FORM_STATE.DONE_LOADING);
				setConfirmationModalOpen(false);
				fetchPowerHoursData().then(() => {
					setConfirmationSubmitting(false);
				});
			})
			.catch((error) => {
				const serverErrorMsg = (error.response && error.response.data)
					? error.response.data.Message
					: error.message;

				let errorMsg = `We can't confirm your time slot right now. Please try again later.`;
		
				if (serverErrorMsg) {
					if (serverErrorMsg.toLowerCase().indexOf("basic meter") >= 0) {
						errorMsg = `Properties with basic meters are not eligible for Power Hours`;
					} else if (serverErrorMsg === "The time slot or event has expired") {
						errorMsg = 'Unable to lock in new time slot as the event has started';
					} else if (serverErrorMsg.toLowerCase().indexOf("mram") >= 0) {
						errorMsg = `Properties with MRAMs are not eligible for Power Hours`;
					}
				}

				setFormState(FORM_STATE.DEFAULT);
				setConfirmationModalOpen(false);
				setSubmitFailMessage(errorMsg);
				setConfirmationSubmitting(false);
			});
	}

	function onConfirmSubmitChange(payload) {

		const endpoint = process.env.REACT_APP_API_BASE_URI + 'PowerHour/change';

		/* Analytics.event({
			category: "Power Hours",
			action: "Lock in time Slot",
			value: payload.AccountId + "|" + payload.PowerHourTimeSlotId,
		}); */																						
		
		setConfirmationSubmitting(true);
		setFormState(FORM_STATE.LOADING);
		axios
			.post(endpoint, payload)
			.then(() => {
				setFormState(FORM_STATE.DONE_LOADING);
				setConfirmationChangeModalOpen(false);
				fetchPowerHoursData().then(() => {
					setConfirmationSubmitting(false);
				});
			})
			.catch((error) => {
				const serverErrorMsg = (error.response && error.response.data)
					? error.response.data.Message
					: error.message;

				let errorMsg = `We can't change your time slot right now. Please try again later.`;
				if (serverErrorMsg) {
					if (serverErrorMsg.toLowerCase().indexOf("basic meter") >= 0) {
						errorMsg = `Properties with basic meters are not eligible for Power Hours`;
					} else if (serverErrorMsg === "Unable to change the power hour time slot because the power hour event time slot expiry time has been reached") {
						errorMsg = 'This time slot cannot be changed as it is within 5 minutes of the start time.';
					} else if (serverErrorMsg === "Unable to change the power hour time slot because the power hour event time slot start time has been reached") {
						errorMsg = 'Unable to lock in new time slot as the event has started';
					} else if (serverErrorMsg.toLowerCase().indexOf("mram") >= 0) {
						errorMsg = `Properties with MRAMs are not eligible for Power Hours`;
					}
				}

				setFormState(FORM_STATE.DEFAULT);
				setConfirmationModalOpen(false);
				setSubmitFailMessage(errorMsg);
				setConfirmationSubmitting(false);
			});

	}

	if (!customer.hasLoaded) {
		return null;
	}

	function renderErrorModal() {
       	return (
			<Modal
				name='confirm-timeslot-error'
				size='tiny'
				open={!!submitFailMessage}
				onClose={handleCloseModal}
				style={{
					color: theme.modal?.color, 
					backgroundColor: theme.modal?.backgroundColor,
				}}
			>
				<Modal.Header>Oops!</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						{submitFailMessage}
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button secondary fluid onClick={handleCloseModal}>OK</Button>
				</Modal.Actions>
			</Modal>
		);

	}

	function renderConfirmModal() {

		if (!confirmingPayload) return null;

		const { powerHours, selectedTimeslot, premises } = confirmingPayload;

		return (
			<Modal
				name="powerhours/confirm"
				size="tiny"
				open={confirmationModalOpen}
				onClose={handleCloseModal}
				className="power-hours-confirmation-modal"
			>
				<Modal.Header>
					
					<Close2Icon
						onClick={handleCloseModal}
						height={20}
						style={{
							position: 'absolute',
							right: '14px',
							top: '12px',
						}}
					/>
					<PowerHoursBannerLogo height={120} />
		
				</Modal.Header>
				<Modal.Content>

					<p className="address">
						{ premises.formattedAddress }
					</p>
					<p>
						{ selectedTimeslot.getFormattedStartDate() }
					</p>
					<p>
						{ selectedTimeslot.hasDuration() ? <>
							{ selectedTimeslot.renderDuration() }
						</> : <>
							{ selectedTimeslot.renderStartTime() } — { selectedTimeslot.renderEndTime() }						
						</> }						
					</p>

					<Form
						noValidate
						onValidSubmit={() => {
							if (!canSubmitConfirmation || confirmationSubmitting) return;
							onConfirmSubmit({
								PowerHourEventId: confirmingPayload.powerHours.id,
								PowerHourTimeSlotId: confirmingPayload.timeslot,
								AccountId: confirmingPayload.property,
							});
						}}
						onValid={onConfirmValid}
						onInvalid={onConfirmInvalid}
					>

						<label className="terms">
							<ValidatedCheckbox
								name="consentTandCs"
								required
								validations="isTrue"
								hideErrorMessage
							/>
							<span>
								I consent to <ExternalLink
									href={TERMS_CONDITIONS_LINK}
									onClick={() => {
										Analytics.event({
											category: "Power Hours",
											action: "View Terms and Conditions",
										});																						
									}}
								>
									Power Hours T&C’s
								</ExternalLink>
							</span>
						</label>


						<Button
							type="submit"
							fluid
							inverted
							size="large"
							disabled={!canSubmitConfirmation || confirmationSubmitting}          
							loading={formState === FORM_STATE.LOADING}
						>Lock in time slot</Button>
					</Form>
					<p style={{fontSize: "0.85em",
								marginTop: "10px",
								
					}}>
					You can edit your time slot up to 5 minutes before it starts.
					</p>
				</Modal.Content>
			</Modal>
		);
	}

	function renderConfirmChangeModal() {

		if (!confirmingChangePayload) return null;

		const { powerHours, selectedTimeslot, premise } = confirmingChangePayload;

		return (
			<Modal
				name="powerhours/confirm"
				size="tiny"
				open={confirmationChangeModalOpen}
				onClose={handleCloseModal}
				className="power-hours-confirmation-modal"
			>
				<Modal.Header>
					
					<Close2Icon
						onClick={handleCloseModal}
						height={20}
						style={{
							position: 'absolute',
							right: '14px',
							top: '12px',
						}}
					/>

					<PowerHoursBannerLogo height={120} />
		
				</Modal.Header>
				<Modal.Content>
				    <p className="address">
					Your new time slot is:
					</p>
					<p className="address">
						{ premise.formattedAddress }
					</p>
					<p>
						{ selectedTimeslot.getFormattedStartDate() }
					</p>
					<p>
						{ selectedTimeslot.hasDuration() ? <>
							{ selectedTimeslot.renderDuration() }
						</> : <>
							{ selectedTimeslot.renderStartTime() } — { selectedTimeslot.renderEndTime() }						
						</> }						
					</p>

					<Form
						noValidate
						onValidSubmit={() => {
							if (!canSubmitConfirmation || confirmationSubmitting) return;
							onConfirmSubmitChange({
								PowerHourEventId: confirmingChangePayload.powerHours.id,
								PowerHourTimeSlotId: confirmingChangePayload.timeslot,
								PowerHourTimeSlotPreviousId: confirmingChangePayload.powerHours.acceptedTimeSlot.id,
								AccountId: confirmingChangePayload.premise.parentAccountID,
								AccountPreviousId: confirmingChangePayload.powerHours.accountId
							});
						}}
						onValid={onConfirmValid}
						onInvalid={onConfirmInvalid}
					>

						<label className="terms">
							<ValidatedCheckbox
								name="consentTandCs"
								required
								validations="isTrue"
								hideErrorMessage
							/>
							<span>
								I consent to <ExternalLink
									href={TERMS_CONDITIONS_LINK}
									onClick={() => {
										Analytics.event({
											category: "Power Hours",
											action: "View Terms and Conditions",
										});																						
									}}
								>
									Power Hours T&C’s
								</ExternalLink>
							</span>
						</label>


						<Button
							type="submit"
							fluid
							inverted
							size="large"
							disabled={!canSubmitConfirmation || confirmationSubmitting}          
							loading={formState === FORM_STATE.LOADING}
						>Lock in time slot change</Button>
					</Form>
					<p style={{fontSize: "0.85em",
								marginTop: "10px",
								
					}}>
					You can edit your time slot up to 5 minutes before it starts.
					</p>
				</Modal.Content>
			</Modal>
		);
	}

	return (
		<Layout
			accountPicker={false}
			layout={PowerHoursLayout}
			pageTitle="Power Hours"
			/*onPullDownRefresh={props => {
				fetchPowerHoursData();
			}}*/
		>
			
			{renderErrorModal()}
			{renderConfirmModal()}
			{renderConfirmChangeModal()}

			{ loadedPowerHoursData ? <>

				{ totals && <PowerHoursSummary {...totals}/> }

				<Card.Group as={TransitionGroup} itemsPerRow={1}
					style={{
						// Get mobile margins similar to home page with different itemsPerRow
						marginLeft: '-0.5rem',
						marginRight: '-0.5rem',
					}}
				>
					{ powerHoursEvents &&
						powerHoursEvents.map(event => { 
							return (
								<CSSTransition
									key={event.id}
									timeout={animationTimer}
									classNames="card-animation"
								>
									<PowerHoursCard 
										powerHours={event} 
										premises={validPremises} 
										onSubmit={confirmationSubmitting ? null : (payload => showConfirmationModal(payload))} 
										onSubmitChange={showConfirmationChangeModal} 
									/>
								</CSSTransition>
							)
						})
					}
				</Card.Group>
			</> : <>
				<Segment basic style={{minHeight: '100px'}}>
					<Loader
						inverted
						active
						size="medium"
					/>
				</Segment>
			</> }



		</Layout>
	);

})